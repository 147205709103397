import React, { useEffect } from 'react'
import { observer } from 'mobx-react'

import { usePayerStore } from '../../hooks/use-payer-stores'

import { SellerContactsModal } from './seller-contacts-modal'
import { ResetCourseModal } from './reset-course-modal'
import { ProfileSwitchingModal } from './profile-switching-modal'
import { PasswordModal } from './password-modal'
import { EditProfileModal } from './edit-profile-modal'
import { MergeAccountsModal } from './merge-accounts-modal'
import { UserProfileModal } from './user-profile-modal'
import { OrderCancelModal } from './order-cancel-modal'
import { ConfirmBankAccountDeletionModal } from './confirm-bank-account-deletion-modal'
import { BankAccountModal } from './bank-account-modal'
import { BecomeSellerModal } from './become-seller-modal'

export const MODALS_COMPONENTS = {
  sellerContactsModal: SellerContactsModal,
  resetCourseModal: ResetCourseModal,
  profileSwitchingModal: ProfileSwitchingModal,
  passwordModal: PasswordModal,
  editProfileModal: EditProfileModal,
  mergeAccountsModal: MergeAccountsModal,
  userProfileModal: UserProfileModal,
  orderCancelModal: OrderCancelModal,
  confirmBankAccountDeletionModal: ConfirmBankAccountDeletionModal,
  bankAccountModal: BankAccountModal,
  becomeSellerModal: BecomeSellerModal,
}

export const MODALS_IDS = {
  sellerContactsModal: 'sellerContactsModal',
  resetCourseModal: 'resetCourseModal',
  profileSwitchingModal: 'profileSwitchingModal',
  passwordModal: 'passwordModal',
  editProfileModal: 'editProfileModal',
  mergeAccountsModal: 'mergeAccountsModal',
  userProfileModal: 'userProfileModal',
  orderCancelModal: 'orderCancelModal',
  confirmBankAccountDeletionModal: 'confirmBankAccountDeletionModal',
  bankAccountModal: 'bankAccountModal',
  becomeSellerModal: 'becomeSellerModal',
}

export const ModalsRenderer: React.FC = observer(() => {
  const { modalsManager } = usePayerStore()

  useEffect(() => {
    Object.keys(MODALS_IDS).map((modalId) => modalsManager.register(modalId))

    return () => {
      modalsManager.closeAll()
    }
  }, [])

  const registeredModals = modalsManager.getRegisteredModalsKeys()

  return (
    <>
      {registeredModals.map((modalKey) => {
        const Component = MODALS_COMPONENTS[modalKey]
        return <Component key={modalKey} />
      })}
    </>
  )
})
