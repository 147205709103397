import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'
import { createTransfersApi, Transfer, TransfersAPI } from '../api/transfers.api'
import { handleFetchList, WithFetchList } from './actions/fetch-list'
import { handleResetStore, WithResetData } from './actions/reset-data'
import { handlePaginationChange } from './actions/pagination-change'

export class TransfersStore implements WithFetchList<Transfer>, WithResetData {
  declare api: TransfersAPI

  constructor() {
    this.api = createTransfersApi(apiClient)
    makeAutoObservable(this)
  }

  expands = []
  isLoading = false
  list: Transfer[] = []
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }
  scopes = new Map()

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  resetExpands = () => (this.expands = [])

  setList(list: Transfer[]) {
    this.list = list
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  setScope(key: string, value: string | number) {
    this.scopes.set(key, value)
  }

  resetScopes() {
    this.scopes = new Map()
  }

  resetData = () => handleResetStore(this)

  fetchList = async (data?) => await handleFetchList<Transfer>(this, data)

  applyPagination = async (key, value) => await handlePaginationChange<Transfer>(this, key, value)
}
