import { makeAutoObservable } from 'mobx'
import { HTTPResponse } from 'types/responses'
import { getCookies, removeCookies, setCookies } from 'libs/common/cookies'

import { ACTIVE_PROFILES } from 'constants/profile.constants'
import {
  SIGN_UP_NEW_PROFILE_TYPE_PARAM,
  SIGN_UP_NEW_PROFILE_EPID_PARAM,
  SIGN_UP_NEW_PROFILE_EMAIL_PARAM,
} from 'constants/login.constants'
import { ROOT_URL } from 'constants/general.constants'
import { PUBLISHER_AFFILIATE_PROGRAMS_PATH } from 'constants/routes/publisher.constants'

import { ProfileTypes } from 'types/helpers'

import { getAffiliateCookies } from 'utils/affiliatePrograms.utils'
import { setParamsToCookies } from 'utils/cookiesConsents.utils'
import { getSearchParams } from 'utils/queryString.utils'
import { isWindowEnv } from 'utils/env.utils'
import { apiClient } from 'utils/requests.utils'

import { UserApi, createUserApi, UserResp, UpdateItemBody, User } from '../api/user.api'
import { createAffiliateProgramsApi } from '../api/affiliatePrograms.api'
import { acceptItem as acceptAffiliateNode } from '../../publisher/api/affiliateNodes.api'

const affiliateCookiesParams = [
  'parent_publisher',
  'invite_program',
  'affiliate_invitee_username',
  'affiliate_node_token',
]

// TODO: move this check to the helpers and cover by tests
const checkLocale = (resp: UserResp, shouldReload = true) => {
  const { data, error } = resp || {}
  const isShop = getCookies('cabinet_profile_type') === ACTIVE_PROFILES.shop

  if (!isShop && resp && Object.keys(resp).length !== 0 && !error && getCookies('locale') !== data?.locale) {
    setCookies('locale', data?.locale).then(() => {
      if (shouldReload && isWindowEnv()) window.location.reload()
    })
  }
}

export class UserStore {
  declare api: UserApi
  declare affiliateProgramsApi: any
  list: User[] = []
  item: User = {
    profileTypes: [],
    id: '',
    sellerUsername: '',
    email: '',
    locale: '',
    signInCount: 0,
    noPassword: false,
    avatar: {
      s200: '',
      original: '',
    },
    profiles: [],
    name: '',
    timeZoneName: '',
    confirmed: false,
    pendingReconfirm: false,
    unconfirmedEmail: '',
  }

  constructor() {
    makeAutoObservable(this)
    this.api = createUserApi(apiClient)
    this.affiliateProgramsApi = createAffiliateProgramsApi(apiClient)
  }

  becomeSellerModalOpen = false
  isLoading = true
  customInteractionUrl = null

  toggleLoading = (value) => {
    this.isLoading = value
  }

  setItem<T extends HTTPResponse>(res: T) {
    const { data, success } = res || {}
    if (data && success) this.item = data
  }

  async fetchItem(shouldReload?: boolean) {
    this.isLoading = true
    const resp = await this.api.fetchItem()
    this.setItem(resp)
    this.checkRefererLink()
    await this.managePublisher()
    if (this.customInteractionUrl) {
      window.location.href = this.customInteractionUrl
    } else {
      checkLocale(resp, shouldReload)
      this.isLoading = false

      if (this.isBecomeSellerSignIn()) {
        this.toggleBecomeSellerModal(false)
      }
    }
    return resp
  }

  isBecomeSellerSignIn = (): boolean => {
    const preferredProfileType = getCookies(SIGN_UP_NEW_PROFILE_TYPE_PARAM)
    const affiliateCookies = getAffiliateCookies()
    const epid = affiliateCookies?.epid
    const { profileTypes = [] } = this.item
    const currentProfileType = preferredProfileType || ACTIVE_PROFILES.seller
    return (Boolean(preferredProfileType) || epid) && !profileTypes.includes(currentProfileType as ProfileTypes)
  }

  clearBecomeSellerSettings = (): void => {
    removeCookies(SIGN_UP_NEW_PROFILE_TYPE_PARAM)
    removeCookies(SIGN_UP_NEW_PROFILE_EMAIL_PARAM)
    removeCookies(SIGN_UP_NEW_PROFILE_EPID_PARAM)
  }

  toggleBecomeSellerModal = (cleanUp = true): void => {
    //TODO: move modal logic to the modals manager
    this.becomeSellerModalOpen = !this.becomeSellerModalOpen
    if (!this.becomeSellerModalOpen) {
      removeCookies('shared_theme_token')
      removeCookies('shared_theme_type')

      /* Clean Up become seller sign up for existing user flow */
      cleanUp && this.clearBecomeSellerSettings()
    }
  }

  async updateItem(data: UpdateItemBody) {
    this.isLoading = true
    const resp = await this.api.updateItem(data)
    checkLocale(resp)
    this.setItem(resp)
    this.isLoading = false
    return resp
  }

  async managePublisher() {
    const {
      invite_program: affiliateProgramToken,
      affiliate_node_token: affiliateNodeToken,
      parent_publisher: parentPublisher,
    } = this.getAffiliateParams()
    if (this.item.id) {
      if (affiliateProgramToken) {
        await this.manageAffiliateLanding(affiliateProgramToken, { parentPublisher })
      }
      if (affiliateNodeToken) {
        await this.manageAffiliateNodeInvite(affiliateNodeToken)
      }
    }
  }

  getAffiliateParams = (): {
    invite_program?: string
    affiliate_node_token?: string
    parent_publisher?: string
    affiliate_invitee_username?: string
  } => {
    const query = getSearchParams()

    return affiliateCookiesParams.reduce(
      (result, key) => ({
        ...result,
        [key]: query[key] || getCookies(key),
      }),
      {}
    )
  }

  checkRefererLink() {
    const sessionStorage = isWindowEnv() && navigator.cookieEnabled && window.sessionStorage
    const refererLink = sessionStorage ? sessionStorage.getItem('referer_link') : ''
    if (refererLink && this.item.id) {
      this.customInteractionUrl = refererLink
      sessionStorage.removeItem('referer_link')
    }
  }

  setAffiliateCookies = () => {
    const params = getSearchParams()
    if (params.invite_program || params.affiliate_node_token) {
      setParamsToCookies(params)
    }
  }

  async manageAffiliateLanding(affiliateProgramToken: string, data: { parentPublisher: string }) {
    await this.checkPublisher()
    await this.affiliateProgramsApi.accept(affiliateProgramToken, data)
    this.processResponse()
  }

  async manageAffiliateNodeInvite(affiliateNodeToken: string) {
    await this.checkPublisher()
    await acceptAffiliateNode(affiliateNodeToken)
    this.processResponse()
  }

  async checkPublisher() {
    const publisherNotExist = this.item.profileTypes.indexOf('publisher') === -1
    if (publisherNotExist) await this.api.createProfile({ profileType: ACTIVE_PROFILES.publisher })
  }

  processResponse = () => {
    this.clearAffiliateCookies()
    this.customInteractionUrl = `${ROOT_URL}${PUBLISHER_AFFILIATE_PROGRAMS_PATH}`
  }

  clearAffiliateCookies = () => {
    affiliateCookiesParams.forEach((paramName) => setCookies(paramName, ''))
  }

  updateEmail = (data: { email: string; currentPassword: string; recaptcha: any }) => this.api.updateEmail(data)
}
