import { DEFAULT_PAGE } from 'constants/pagination.constants'
import { WithPagination } from './pagination-change'
import { handleFetchList, WithFetchList } from './fetch-list'

export interface WithFilters<I> extends WithPagination, WithFetchList<I> {
  filters: Map<string, number> | null | undefined
  setFilter(key, value): void
  resetFilters(): void
}

export async function handleApplyFilters<I>(store: WithFilters<I>) {
  store.setPagination({ page: DEFAULT_PAGE })
  await handleFetchList(store)
}
