import { WithLoading } from './toggle-loading'

export interface WithFetchItem<I> extends WithLoading {
  item: I
  api: any
  expands?: any[]
  setItem(item: I): void
  setExpands?(value: []): void
}
export async function handleFetchItem<I>(store: WithFetchItem<I>, id: string | number, params) {
  store.setLoading(true)
  const resp = await store.api.fetchItem(id, {
    expand: store.expands,
    ...params,
  })
  const { data, success } = resp || {}
  if (data && success) store.setItem(data)
  store.setLoading(false)
  return resp
}
