import { CUSTOM_CSS_FEATURE_FLAG, CUSTOM_CSS_CONFIG_FIELD_NAME } from 'constants/themes.constants'
import { usePayerStore } from '../../hooks/use-payer-stores'

export const MembershipTheme = () => {
  const { sellersStore, membershipThemesStore } = usePayerStore()
  const { isAppActive } = sellersStore

  const isCustomCssAppActive = isAppActive(CUSTOM_CSS_FEATURE_FLAG)
  const membershipStylesConfig = membershipThemesStore?.item?.prefs

  if (isCustomCssAppActive && membershipStylesConfig && membershipStylesConfig[CUSTOM_CSS_CONFIG_FIELD_NAME]) {
    return (
      <style
        dangerouslySetInnerHTML={{
          __html: membershipStylesConfig[CUSTOM_CSS_CONFIG_FIELD_NAME],
        }}
      />
    )
  }
  return null
}
