import { useEffect } from 'react'

import { appendScript } from 'utils/dom.utils'
import { getCookiebotScript } from 'utils/cookiesConsents.utils'

import { ELOPAGE_COOKIEBOT_CONFIG } from 'constants/general.constants'

export const CookieBot = () => {
  useEffect(() => {
    const { src, attributes } = getCookiebotScript(
      ELOPAGE_COOKIEBOT_CONFIG.cookiebotId,
      ELOPAGE_COOKIEBOT_CONFIG.cookiebotMode
    )
    appendScript(src, attributes, false)
  }, [])

  return null
}
