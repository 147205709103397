import { makeAutoObservable } from 'mobx'

interface ModalProps {
  [key: string]: any
}

export class ModalsManager {
  registeredModals: Map<string, ModalProps> = new Map()
  openedModals: Map<string, ModalProps> = new Map()

  constructor() {
    makeAutoObservable(this)
  }

  register<T>(id: string, props?: T) {
    if (!this.registeredModals.has(id)) {
      this.registeredModals.set(id, props)
    }
  }

  isOpen(id: string): boolean {
    return this.openedModals.has(id)
  }

  open<T>(id: string, props?: T) {
    if (!this.registeredModals.has(id)) {
      console.error(`Modal with id "${id}" is not registered.`)
      throw new Error(`Modal with id "${id}" is not registered.`)
    }
    if (this.openedModals.has(id)) {
      return
    }

    this.openedModals.set(id, props)
  }

  close(id: string) {
    if (!this.registeredModals.has(id)) {
      console.error(`Cannot close modal "${id}": it is not registered.`)
      throw new Error(`Modal with id "${id}" is not registered.`)
    }
    if (!this.openedModals.has(id)) {
      return
    }

    this.openedModals.delete(id)
  }

  closeAll() {
    this.openedModals.clear()
  }

  getProps<T>(id: string): T | undefined {
    const props = this.openedModals.get(id)
    const defaultProps = this.registeredModals.get(id)

    return (props || defaultProps) as T
  }

  setProps<T>(id: string, newProps: T) {
    if (!this.registeredModals.has(id)) {
      console.error(`Cannot update props for modal "${id}": it is not registered.`)
      throw new Error(`Modal with id "${id}" is not registered.`)
    }
    if (!this.openedModals.has(id)) {
      return
    }

    this.openedModals.set(id, newProps)
  }

  getModal<T>(id: string) {
    const isOpen = this.isOpen(id)
    const props = this.getProps<T>(id)

    return { isOpen, ...props }
  }

  getRegisteredModalsKeys() {
    return Array.from(this.registeredModals.keys())
  }
}
