import React from 'react'
import { observer } from 'mobx-react'

import { notify } from 'libs/common/notify'

import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloCopyIcon } from '@elo-ui/components/icons/regular'
import { EloButton } from '@elo-ui/components/elo-button'

import { MODALS_IDS } from '../index'

import { usePayerStore } from '../../../hooks/use-payer-stores'

import './seller-contacts-modal.scss'

export interface SellerContactsModalProps {
  supportFirstName: string
  supportLastName: string
  supportEmail: string
  supportPhone: string
}

export const SellerContactsModal: React.FC = observer(() => {
  const { modalsManager } = usePayerStore()
  const { isOpen, supportFirstName, supportLastName, supportEmail, supportPhone } =
    modalsManager.getModal<SellerContactsModalProps>(MODALS_IDS.sellerContactsModal)

  const CONTENT = [
    {
      title: I18n.t('react.shared.nav.support'),
      value: [supportFirstName, supportLastName].filter(Boolean).join(' '),
      buttonText: I18n.t('react.payer.products.actions.copy_seller_name'),
      isVisible: !!supportFirstName || !!supportLastName,
    },
    {
      title: I18n.t('react.shared.email'),
      value: supportEmail,
      buttonText: I18n.t('react.payer.products.actions.copy_e-mail'),
      isVisible: !!supportEmail,
    },
    {
      title: I18n.t('react.shared.phone_number'),
      value: supportPhone,
      buttonText: I18n.t('react.payer.products.actions.copy_phone_number'),
      isVisible: !!supportPhone,
    },
  ]

  const handleClose = () => modalsManager.close(MODALS_IDS.sellerContactsModal)

  return (
    <EloModal className='seller-contacts-modal' isOpen={isOpen} onClose={handleClose}>
      <EloModalHeader>{I18n.t('react.payer.common.contact_seller')}</EloModalHeader>
      <EloModalBody>
        <div className='seller-contacts-modal__content'>
          {CONTENT.map((item, index) => (
            <div key={index}>
              {item.isVisible && (
                <div className='seller-contacts-modal__support-info'>
                  <div>
                    <div className='seller-contacts-modal__support-info--title'>{item.title}</div>
                    <div className='seller-contacts-modal__support-info--text'>{item.value}</div>
                  </div>
                  <div className='seller-contacts-modal__support-info--action'>
                    <span>{item.buttonText}</span>
                    <EloCopyIcon
                      onClick={() => {
                        navigator.clipboard.writeText(String(item.value))
                        notify('success', I18n.t('react.shared.notific.copied'))
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--end'>
        <EloButton onClick={handleClose}>{I18n.t('react.shared.close')}</EloButton>
      </EloModalFooter>
    </EloModal>
  )
})
