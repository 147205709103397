import React from 'react'
import classNames from 'classnames'

import { SIZES } from '../../constants/general.constants'

import './loading-spinner.scss'

type sizeKeys = keyof typeof SIZES

interface Props {
  loading?: boolean
  className?: string
  loaderClassName?: string
  size?: (typeof SIZES)[sizeKeys]
}
/**
 * Loading Spinner component
 */
export const EloLoadingSpinner: React.FC<Props> = ({
  size = SIZES.medium,
  loading = true,
  className,
  loaderClassName,
}) => {
  const componentClassNames = classNames('elo-loading-spinner', className)
  const loaderClassNames = classNames(
    'elo-loading-spinner__loader',
    `elo-loading-spinner__loader--${size}`,
    loaderClassName
  )

  return loading ? (
    <div className={componentClassNames}>
      <div className={loaderClassNames} />
    </div>
  ) : null
}
