import { makeAutoObservable } from 'mobx'

import { getElopageConfig } from 'utils/elopageConfig.utils'
import { apiClient } from 'utils/requests.utils'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'

import { SellersApi, Seller, createSellersApi, defaultSeller } from '../api/sellers.api'
import { WithFetchItem, handleFetchItem } from './actions/fetch-item'
import { WithFetchList, handleFetchList, handleFetchFullList } from './actions/fetch-list'
import { WithResetData, handleResetStore } from './actions/reset-data'

export class SellersStore implements WithResetData, WithFetchItem<Seller>, WithFetchList<Seller> {
  declare api: SellersApi
  list: Seller[] = []
  item: Seller = defaultSeller
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }
  expands = null
  scopes = new Map()

  constructor() {
    makeAutoObservable(this)
    this.api = createSellersApi(apiClient)
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setItem(item: Seller) {
    this.item = item
  }

  resetScopes() {
    this.scopes = new Map()
  }

  resetItem() {
    this.item = null
  }

  fetchItem = (id, data) => handleFetchItem<Seller>(this, id, data)

  setList(list: Seller[]) {
    this.list = list
  }

  fetchList = async (data?) => await handleFetchList<Seller>(this, data)

  setExpands = (value) => {
    this.expands = [...this.expands, ...value]
  }

  resetExpands = () => (this.expands = [])

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  fetchFullList = async (data = {}) => await handleFetchFullList<Seller>(this, data)

  resetData = () => handleResetStore(this)

  isAppActive = (key, id?) => {
    const { optionKeys } = (id ? this.list.find((item) => item.id === id) : this.item) || {}

    return optionKeys && optionKeys.includes(key)
  }

  get detectedDomainLogo() {
    const { host } = window.location
    const webHost = getElopageConfig('webHost')
    const domainName = host !== webHost ? host : null

    // if custom domain
    if (domainName) {
      return (this.list.find((s) => s.domainName === domainName) || {}).domainLogo
    }

    // if only one seller
    if (this.list.length === 1) {
      return (this.list[0] || {}).domainLogo
    }

    return null
  }
}
