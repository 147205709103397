import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse } from 'types/responses'

const BASE_URL = (sellerId: number) => `/payer/sellers/${sellerId}/product_groups`

export interface ProductGroup {
  id: string | number
  name: string
}

export const createProductGroupsApi = ({ GET }: ApiClient) => ({
  fetchList: ({ sellerId, ...data }) => GET<PaginatedResponse<ProductGroup[]>>(BASE_URL(sellerId), data),
})

export type ProductGroupsApi = ReturnType<typeof createProductGroupsApi>
