import { ApiClient } from 'utils/requests.utils'

const BASE_URL = (courseSessionId, lessonId) => `/payer/course_sessions/${courseSessionId}/lessons/${lessonId}/quiz`

export const createQuizzesApi = ({ GET, POST }: ApiClient) => ({
  fetchQuiz: (courseSessionId: any, lessonId: any) => GET(BASE_URL(courseSessionId, lessonId)),
  submitQuiz: (courseSessionId: any, lessonId: any, data: Record<string, any>) =>
    POST<any>(`${BASE_URL(courseSessionId, lessonId)}/submit_attempt`, data),
})

export type QuizzesApi = ReturnType<typeof createQuizzesApi>
