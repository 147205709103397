import { makeAutoObservable } from 'mobx'

import { notify } from 'libs/common/notify'

import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'

import { apiClient } from 'utils/requests.utils'
import { CourseSessionsApi, createCourseSessionApi, CourseSession as Item } from '../api/courseSessions.api'

import { handleFetchItem, WithFetchItem } from './actions/fetch-item'
import { handleFetchList, WithFetchList } from './actions/fetch-list'

export class CourseSessionsStore implements WithFetchItem<Item>, WithFetchList<Item> {
  declare api: CourseSessionsApi

  item: Item
  list: Item[]
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }
  expands = []
  scopes = new Map()

  constructor() {
    makeAutoObservable(this)
    this.api = createCourseSessionApi(apiClient)
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setItem(item: Item) {
    this.item = item
  }

  resetItem() {
    this.item = null
  }

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  resetExpands = () => (this.expands = [])

  setList(list: Item[]) {
    this.list = list
  }

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  cancelSubscription = async (id: number) => {
    this.isLoading = true

    const resp = await this.api.cancelItemSubscription(id)
    if (resp.success) {
      notify('success', I18n.t('react.shared.renew.messages.unsubscribeSuccess'))
      await this.fetchList()
    }

    this.isLoading = false
  }

  continueSubscription = async (id: number) => {
    this.isLoading = true

    const resp = await this.api.continueItemSubscription(id)

    if (resp.success) {
      notify('success', I18n.t('react.shared.renew.messages.success'))
      await this.fetchList()
    }

    this.isLoading = false
  }

  dripInToggle = async (id: number | string) => {
    this.isLoading = true

    const resp = await this.api.dripInToggle(id)

    if (resp.success) {
      notify('success', I18n.t('react.cabinet.notific.success'))
      await this.fetchList()
    }

    this.isLoading = false
  }

  fetchList = async (data = {}) => await handleFetchList<Item>(this, data)

  fetchItem = async (id: string | number, data = {}) => await handleFetchItem<Item>(this, id, data)
}
