import { toJS } from 'mobx'
import { DEFAULT_FULL_LIST, DEFAULT_PAGE } from 'constants/pagination.constants'
import { WithLoading } from './toggle-loading'
import { WithPagination } from './pagination-change'

export interface WithFetchList<I> extends WithLoading, WithPagination {
  list: I[]
  scopes: any
  filters?: Map<string, number> | null | undefined
  expands: any[]
  api: any
  setList(list: I[]): void
  setExpands(value: []): void
  resetExpands(): void
}

export async function handleFetchList<I>(store: WithFetchList<I>, params = {}) {
  store.setLoading(true)

  const resp = await store.api.fetchList({
    expand: toJS(store.expands),
    page: store.pagination.page,
    per: store.pagination.per,
    ...params,
    ...(store.scopes && Object.fromEntries(store.scopes)),
    ...(store.filters && Object.fromEntries(store.filters)),
  })
  const { success, data } = resp

  if (success) {
    const { list = [], totalCount = 0 } = data || {}
    store.setList(list)
    store.setPagination({ total: totalCount })
  }

  store.setLoading(false)
  return resp
}

export async function handleFetchFullList<I>(store: WithFetchList<I>, params = {}) {
  const resp = await handleFetchList(store, {
    ...params,
    page: DEFAULT_PAGE,
    per: DEFAULT_FULL_LIST,
  })

  const { success, data } = resp
  if (data && success) {
    const { total, totalCount } = data

    if (total > DEFAULT_FULL_LIST || totalCount > DEFAULT_FULL_LIST) {
      return await handleFetchList(store, {
        ...params,
        per: total || totalCount,
      })
    }
  }

  return resp
}
