import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'

import { DEFAULT_FULL_LIST, DEFAULT_PAGE } from 'constants/pagination.constants'

import { SellerFontsApi, createSellerFontsApi, SellerFonts } from '../api/sellerFonts.api'

export class SellerFontsStore {
  declare api: SellerFontsApi
  storeName = 'SellerFontsStore'
  list = []

  constructor() {
    this.api = createSellerFontsApi(apiClient)
    makeAutoObservable(this)
  }

  async fetchList(
    data = {
      sellerId: null,
    }
  ) {
    const resp = await this.api.fetchList(data.sellerId)

    if (resp.success) {
      const { list = [] } = resp.data || ({} as SellerFonts)
      this.list = list
    }

    return resp
  }

  async fetchFullList(data = {}) {
    const resp = await this.fetchList({
      // @ts-ignore
      page: DEFAULT_PAGE,
      per: DEFAULT_FULL_LIST,
      ...data,
    })

    if (resp.success) {
      const { list = [] } = resp.data || ({} as SellerFonts)
      this.list = list
    }

    return resp
  }
}
