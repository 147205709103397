import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'
import { SellableItem } from '../api/sellables.api'
import { SellableItemsAPI, createSellableItemsApi } from '../api/sellableItems.api'
import { handleFetchFullList, WithFetchList } from './actions/fetch-list'
import { handlePaginationChange } from './actions/pagination-change'
import { handleResetStore } from './actions/reset-data'

export class SellableItemsStore implements WithFetchList<SellableItem> {
  declare api: SellableItemsAPI
  list: SellableItem[]
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }
  expands = []
  scopes = new Map()

  constructor() {
    makeAutoObservable(this)
    this.api = createSellableItemsApi(apiClient)
  }
  setLoading(value: boolean) {
    this.isLoading = value
  }

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  resetExpands = () => (this.expands = [])

  setList(list: SellableItem[]) {
    this.list = list
  }

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  setScope(key, value) {
    this.scopes.set(key, value)
  }

  resetScopes() {
    this.scopes = new Map()
  }

  fetchFullList = async (data = {}) => await handleFetchFullList(this, data)

  applyPagination = async (key, value) => await handlePaginationChange<SellableItem>(this, key, value)

  resetData = () => handleResetStore(this)
}
