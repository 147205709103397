import { makeAutoObservable } from 'mobx'
import * as api from '../api/orderVatChange.api'

export class OrderVatChangeStore {
  storeName = 'OrderVatChangeStore'
  childApi = api
  isLoading = false

  constructor() {
    makeAutoObservable(this)
  }

  setLoading(value) {
    this.isLoading = value
  }

  reject = async (orderId) => {
    this.setLoading(true)
    await api.rejectVatChange(orderId)
    this.setLoading(false)
  }

  accept = async (orderId) => {
    this.setLoading(true)
    await api.acceptVatChange(orderId)
    this.setLoading(false)
  }
}
