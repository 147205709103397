import { makeAutoObservable } from 'mobx'

import { apiClient } from 'utils/requests.utils'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'

import { handleFetchFullList, handleFetchList, WithFetchList } from './actions/fetch-list'
import { handleFetchItem, WithFetchItem } from './actions/fetch-item'
import { LessonStatus, LessonStatusesApi, createLessonStatusesApi } from '../api/lessonStatuses.api'

export class LessonStatusesStore implements WithFetchList<LessonStatus>, WithFetchItem<LessonStatus> {
  declare api: LessonStatusesApi
  list: LessonStatus[] = []
  item: LessonStatus = {
    id: '',
    name: '',
  }
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }

  expands = []
  scopes = new Map()

  constructor() {
    this.api = createLessonStatusesApi(apiClient)
    makeAutoObservable(this)
  }

  setLoading(value) {
    this.isLoading = value
  }

  setItem(item: LessonStatus) {
    this.item = item
  }

  resetItem() {
    this.item = null
  }

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  resetExpands = () => (this.expands = [])

  setList(list: LessonStatus[]) {
    this.list = list
  }

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  setScope(key, value) {
    this.scopes.set(key, value)
  }

  fetchItem = async (id, data?) => await handleFetchItem<LessonStatus>(this, id, data)

  fetchFullList = async (data) => await handleFetchFullList(this, data)

  fetchList = async (data?) => await handleFetchList<LessonStatus>(this, data)
}
