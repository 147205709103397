import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'

import { createInvoicesApi, Invoice, InvoicesAPI } from '../api/invoices.api'
import { handleResetStore, WithResetData } from './actions/reset-data'
import { handleFetchList, WithFetchList } from './actions/fetch-list'
import { handlePaginationChange } from './actions/pagination-change'

export class InvoicesStore implements WithResetData, WithFetchList<Invoice> {
  declare api: InvoicesAPI

  constructor() {
    this.api = createInvoicesApi(apiClient)
    makeAutoObservable(this)
  }

  list: Invoice[] = []
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }
  expands = []
  scopes = new Map()

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setList(list: Invoice[]) {
    this.list = list
  }

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  resetExpands = () => (this.expands = [])

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  setScope(key, value) {
    this.scopes.set(key, value)
  }

  resetScopes() {
    this.scopes = new Map()
  }

  resetData = () => handleResetStore(this)

  fetchList = async (data?) => await handleFetchList<Invoice>(this, data)

  applyPagination = async (key, value) => await handlePaginationChange<Invoice>(this, key, value)
}
