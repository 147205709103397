import React, { useRef } from 'react'

import { notify } from 'libs/common/notify'

import { EloButton } from '@elo-ui/components/elo-button'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import { EloDownloadIcon, EloInfoIcon } from '@elo-ui/components/icons/regular'

import { FILE_ACCEPT_VERIFICATION_FILES, FILE_UNITS } from 'constants/files.constants'

import { convertToBytes } from '../../../helpers/convert-to-bytes'
import { convertFromBytes } from '../../../helpers/convert-from-bytes'

import './file-upload-button.scss'

interface File {
  name: string
  size: number
  type: string
}

interface Props {
  label: string
  description: string
  buttonText: string
  required?: boolean
  maxFileSize?: number
  onUpload: (file: File) => void
  onError: (error: any) => void
}

export const FileUploadButton: React.FC<Props> = ({
  label,
  description,
  buttonText,
  onUpload,
  onError,
  maxFileSize = convertToBytes(6, FILE_UNITS.mb),
  required = false,
}) => {
  const fileInputRef = useRef(null)

  const handleButtonClick = () => {
    fileInputRef.current.click()
  }

  const handleFileChange = (event) => {
    try {
      const files: File[] = Array.from(event.target.files)
      const file = files[0]

      if (maxFileSize && file.size > maxFileSize) {
        notify('error', I18n.t('react.shared.notific.max_file_size', { size: convertFromBytes(maxFileSize) }))
      } else {
        if (onUpload && file) {
          onUpload(file)
        }
      }
    } catch (error) {
      if (onError) {
        onError(error)
      }
    }
  }

  return (
    <div className='file-upload-button'>
      {label && (
        <div className='file-upload-button__label'>
          {label}
          {required && <span className='file-upload-button__required'>*</span>}

          <EloTooltip content={I18n.t('react.shared.cashout_verification_popover')} placement='top'>
            <EloInfoIcon size={16} />
          </EloTooltip>
        </div>
      )}
      {description && <div className='file-upload-button__description'>{description}</div>}
      <EloButton variant='secondary' leftIcon={<EloDownloadIcon />} onClick={handleButtonClick}>
        {buttonText}
      </EloButton>
      <input
        type='file'
        ref={fileInputRef}
        style={{ display: 'none' }}
        accept={FILE_ACCEPT_VERIFICATION_FILES}
        onChange={handleFileChange}
      />
    </div>
  )
}
