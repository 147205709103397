import React from 'react'
import { observer } from 'mobx-react'

import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloWarningIcon } from '@elo-ui/components/icons/regular'

import { usePayerStore } from '../../../hooks/use-payer-stores'

import { MODALS_IDS } from '../index'

import './confirm-bank-account-deletion-modal.scss'

interface ConfirmBankAccountDeletionModalProps {
  onSubmit: () => void
}

export const ConfirmBankAccountDeletionModal = observer(() => {
  const { modalsManager } = usePayerStore()
  const { isOpen, onSubmit } = modalsManager.getModal<ConfirmBankAccountDeletionModalProps>(
    MODALS_IDS.confirmBankAccountDeletionModal
  )

  const handleClose = () => modalsManager.close(MODALS_IDS.confirmBankAccountDeletionModal)
  const handleSubmit = () => {
    onSubmit()
    handleClose()
  }

  return (
    <EloModal className='confirm-bank-account-deletion-modal' isOpen={isOpen} onClose={handleClose}>
      <EloModalHeader className='confirm-bank-account-deletion-modal__header'>
        <span className='confirm-bank-account-deletion-modal__header-icon'>
          <EloWarningIcon size={32} />
        </span>
        {I18n.t('react.shared.delete_bank_account_confirmation')}
      </EloModalHeader>
      <EloModalBody>{I18n.t('react.shared.delete_bank_account_consequences')}</EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--end'>
        <EloButton onClick={handleClose}>{I18n.t('react.shared.close')}</EloButton>
        <EloButton variant='danger' onClick={handleSubmit}>
          {I18n.t('react.shared.confirm')}
        </EloButton>
      </EloModalFooter>
    </EloModal>
  )
})
