import React from 'react'

import { createRoot } from 'react-dom/client'
import { useFeatureGate } from '@statsig/react-bindings'

import ErrorBoundary from 'ui/error-boundary/ErrorBoundary'

import { StatsigContainer } from './StatsigContainer'

import { PayerApp } from './app'
import { NewPayerApp } from '../payer-new'

const App = () => {
  const isNewPayer = useFeatureGate('is_new_payer_experience')

  if (isNewPayer?.value) {
    return <NewPayerApp />
  }

  return <PayerApp />
}

const root = createRoot(document.getElementById('root'))

root.render(
  // <React.StrictMode>
  <ErrorBoundary>
    <StatsigContainer>
      <App />
    </StatsigContainer>
  </ErrorBoundary>
  // </React.StrictMode>
)
