import { ApiClient } from 'utils/requests.utils'

import { PaginatedResponse } from 'types/responses'
import { Nullable } from 'types/helpers'

const BASE_URL = '/payer/invoices'

interface CfgsData {
  isBusiness: boolean
  withVatNo: boolean
  mustPayVat: boolean
  calcsVatRate: Nullable<number>
  privateVatRate: Nullable<number>
  privateVatText: string
  businessVatRate: number
  businessVatText: string
  transferVatRate: number
}

interface Rate {
  net: number
  vat: number
  count: number
  gross: number
  netSum: number
  vatSum: number
  dVatSum: number
  grossSum: number
  dGrossSum: number
}

interface SummTable extends Rate {
  vatByRate: Rate
  docVersion: string
}

interface TableData {
  mainTable: {
    name: string
    itemsDe: {
      name: string
    }[]
    itemsEn: {
      name: string
    }[]
    cfgsData: CfgsData
    rowsData: {
      rate: {
        net: number
        vat: number
        count: number
        gross: number
        netSum: number
        vatSum: number
        dVatSum: number
        grossSum: number
        dGrossSum: number
        vatByRate: Rate
      }
    }
    shortDescription: Nullable<string>
  }
  summTable: SummTable
}

interface Order {
  id: number
  payerId: number
  currencyId: number
  paymentForm: string
  cardProvider: string
  periodType: string
  token: string
  totalAmount: string
  unpaidAmount: string
  paidAmount: string
  overdue: Nullable<string>
  creditMemoAmount: number
  seller: {
    username: string
  }
  product: {
    id: number
    name: string
    link: string
    paymentLink: string
  }
  coupon: Nullable<Record<string, unknown>>
  affiliateNode: Nullable<Record<string, unknown>>
}

interface Transfer {
  idForSeller: number
  state: string
  form: string
  successDate: string
}

export interface Invoice {
  id: number
  state: string
  autocreated: boolean
  deadline: Nullable<string>
  docVersion: string
  edited: boolean
  form: string
  fromBills: boolean
  issued: Nullable<string>
  number: string
  orderId: number
  parentId: Nullable<number>
  payment: {
    transferId: number
  }
  price: number
  publisherId: Nullable<number>
  remindedAt: Nullable<string>
  reminderDelay: Nullable<string>
  resellerId: Nullable<number>
  sellerBuyerData: any
  sellerId: number
  tableData: TableData[]
  token: string
  total: string
  createdAt: string
  updatedAt: string
  order: Order
  transfers: Transfer[]
}

export const createInvoicesApi = ({ GET }: ApiClient) => ({
  fetchList: (data): Promise<PaginatedResponse<Invoice[]>> => GET(BASE_URL, data),
})

export type InvoicesAPI = ReturnType<typeof createInvoicesApi>
