import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'

import { WithFetchItem, handleFetchItem } from './actions/fetch-item'
import { WithResetData, handleResetStore } from './actions/reset-data'
import { MembershipTheme, MembershipThemeApi, createMembershipApi, ThemePageType } from '../api/membershipThemes.api'

export class MembershipThemesStore implements WithFetchItem<MembershipTheme>, WithResetData {
  declare api: MembershipThemeApi
  item: MembershipTheme
  expands = []
  isLoading = false

  constructor() {
    this.api = createMembershipApi(apiClient)
    makeAutoObservable(this)
  }

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  setItem(item: MembershipTheme) {
    this.item = item
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  fetchItem = async (id, data = {}) => await handleFetchItem<MembershipTheme>(this, id, data)

  resetData = () => handleResetStore(this)

  getThemePagesBySlug = (slug: string) => {
    if (!this.item?.themePages) {
      return {} as ThemePageType
    }

    return this.item?.themePages.find((themePage) => themePage.slug === (slug || ''))
  }
}
