import React, { useState } from 'react'
import classNames from 'classnames'

import { SidebarProps, SidebarItemProps } from '@elo-ui/types'

import { EloSidebarItem } from './components/elo-sidebar-item'
import { EloSidebarLogo } from './components/elo-sidebar-logo'
import { EloSidebarButton } from './components/elo-sidebar-button'

import './elo-sidebar.scss'

export const EloSidebar: React.FC<SidebarProps> = ({
  rootLink = '/',
  logo = '',
  footerLogo = '',
  items = [],
  isMobile = false,
  isOpen = true,
  copyright = '©2023 ablefy',
  activeItem = null,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(!isOpen)
  const [isHovered, setIsHovered] = useState(false)
  const [currentActiveItem, setCurrentActiveItem] = useState<SidebarItemProps>(activeItem)
  const [openedItem, setOpenedItem] = useState<SidebarItemProps>(null)

  const sidebarCollapsed = (isMobile && isCollapsed) || (isCollapsed && !isHovered)
  const sidebarClass = classNames('elo-sidebar', {
    'elo-sidebar--collapsed': sidebarCollapsed,
    'elo-sidebar--mobile': isMobile,
    'elo-sidebar--desktop': !isMobile,
  })

  const isTouchDevice = 'ontouchstart' in window || navigator.maxTouchPoints > 0

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed)
  }

  const handleHover = () => {
    if (!isMobile && !isTouchDevice) {
      setIsHovered(!isHovered)
    }
  }

  const handleItemClick = (item) => {
    setCurrentActiveItem(item)

    if (isMobile || isTouchDevice) {
      handleToggle()
    }
  }

  return (
    <div className={sidebarClass}>
      <div className='elo-sidebar__header'>
        <EloSidebarButton isCollapsed={isCollapsed} onToggle={handleToggle} />
        <EloSidebarLogo link={rootLink} logo={logo} />
      </div>
      <div className='elo-sidebar__content' onMouseEnter={handleHover} onMouseLeave={handleHover}>
        <ul className='elo-sidebar__menu'>
          {items.map((item) => (
            <EloSidebarItem
              key={item?.id}
              activeItem={currentActiveItem}
              openedItem={openedItem}
              onItemCLick={handleItemClick}
              onOpenCLick={setOpenedItem}
              {...item}
            />
          ))}
        </ul>
      </div>
      <div className='elo-sidebar__footer'>
        {copyright && (
          <>
            {sidebarCollapsed && <div className='elo-sidebar__footer-logo'>{footerLogo}</div>}
            {!sidebarCollapsed && <div className='elo-sidebar__copyright'>{copyright}</div>}
          </>
        )}
      </div>
    </div>
  )
}
