import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'
import { ProfileTypes } from 'types/helpers'
const BASE_URL = '/common/user_profiles'

export interface Profile {
  id: number
  profileTypesWithIds?: Record<ProfileTypes, number>
  publicName?: number
  vatPayer: boolean
  countryCode: string
  street: string
  streetNumber: string
  zip: string
  city: string
  company: string
  firstName: string
  lastName: string
}

export const createUserProfilesApi = ({ GET, PUT, POST }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<Profile[]>>(BASE_URL, data),
  fetchItem: (id: string | number, data) => GET<Response<Profile>>(`${BASE_URL}/${id}`, data),
  updateItem: (id: string | number, data) => PUT<Response<Profile>>(`${BASE_URL}/${id}`, data),
  createItem: (data) => POST<Response<Profile>>(BASE_URL, data),
})

export type UserProfilesApi = ReturnType<typeof createUserProfilesApi>
