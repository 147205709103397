import React from 'react'
import classNames from 'classnames'

import './elo-stepper.scss'

interface Step {
  name?: React.ReactNode
}

interface Props {
  steps: Step[] | number
  activeStep: number
  className?: string
  setActiveStep: (step: number) => void
  errorStepNumber?: number
  withSequenceNumber?: boolean
  erroredSteps?: number[]
  completedSteps?: number[]
  isClickable?: boolean
  isLinear?: boolean
}

interface EloStepperItemProps extends Omit<Props, 'steps'> {
  step: Step
  stepsLength: number
  index: number
  isStepperWithLabels: boolean
  isCompleted?: boolean
  isErrored: boolean
  isClickable?: boolean
  lastCompletedStep?: number
}

const EloStepperItem: React.FC<EloStepperItemProps> = (props) => {
  const {
    step,
    withSequenceNumber,
    activeStep,
    isCompleted,
    isErrored,
    setActiveStep,
    index,
    isStepperWithLabels,
    stepsLength,
    isClickable,
    isLinear,
    lastCompletedStep,
  } = props

  const { name } = step || {}
  const isItemClickable = !isLinear || (isLinear && isClickable && (isCompleted || index === lastCompletedStep + 1))

  const stepClasses = classNames('elo-stepper__step', {
    'elo-stepper__step--first': index === 0,
    'elo-stepper__step--last': index === stepsLength - 1,
    'elo-stepper__step--with-label': name || withSequenceNumber,
    'elo-stepper__step--in-progress': index === activeStep,
    'elo-stepper__step--completed': isCompleted,
    'elo-stepper__step--error': isErrored,
  })
  const stepContainerClasses = classNames('elo-stepper__step-container', {
    'elo-stepper__step-container--with-label': isStepperWithLabels,
    'elo-stepper__step-container--with-hover': isItemClickable && index !== activeStep,
  })

  const onStepClick = () => {
    if (!isItemClickable) {
      return
    }

    setActiveStep(index)
  }

  return (
    <div key={index} className={stepContainerClasses} onClick={onStepClick} data-testid={`stepper-step-${index}`}>
      <div className={stepClasses} />
      {(withSequenceNumber || name) && (
        <div className='elo-stepper__step-label'>
          {withSequenceNumber && <div className='elo-stepper__step-id'>{`${index + 1}.`}</div>}
          {name && <div className='elo-stepper__step-name'>{name}</div>}
        </div>
      )}
    </div>
  )
}

export const EloStepper: React.FC<Props> = (props) => {
  const {
    steps = [],
    activeStep,
    className,
    setActiveStep,
    erroredSteps = [],
    completedSteps = [],
    withSequenceNumber = false,
    isClickable = false,
    isLinear = true,
  } = props
  const isStepperWithLabels = Array.isArray(steps) || withSequenceNumber
  const stepperClasses = classNames(
    'elo-stepper',
    {
      'elo-stepper--with-labels': isStepperWithLabels,
    },
    className
  )
  const calculatedSteps = Array.isArray(steps) ? steps : new Array(steps).fill(null)

  return (
    <div className={stepperClasses}>
      {calculatedSteps.map((step, index) => (
        <EloStepperItem
          key={index}
          step={step}
          index={index}
          withSequenceNumber={withSequenceNumber}
          activeStep={activeStep}
          isCompleted={completedSteps.includes(index)}
          lastCompletedStep={completedSteps[completedSteps.length - 1]}
          isErrored={erroredSteps.includes(index)}
          setActiveStep={setActiveStep}
          stepsLength={calculatedSteps.length}
          isStepperWithLabels={isStepperWithLabels}
          isClickable={isClickable}
          isLinear={isLinear}
        />
      ))}
    </div>
  )
}
