import { ApiClient } from 'utils/requests.utils'
import { Response } from 'types/responses'
import { Nullable } from 'types/helpers'

const BASE_URL = '/payer/membership_themes'

export interface MembershipTheme {
  copyFromId: Nullable<number>
  createdAt: string
  updatedAt: string
  default: boolean
  id: number
  name: string
  parentId: Nullable<number>
  prefs: {
    customCss: string
    footer: PrefsFooter
    header: PrefsHeader
    screenshot: string
    showFooter: boolean
    showHeader: boolean
    footerHidden: boolean
    headerHidden: boolean
  }
  products?: any[]
  sellerId: number
  sharingId: Nullable<number>
  sharingItemId: Nullable<number>
  template: boolean
  themePages?: ThemePageType[]
}

export interface PrefsHeader {
  accountFontColor: string
  accountFontFamily: string
  accountFontSize: string
  accountFontStyle: string
  accountIconVolor: string
  facebook: string
  instagram: string
  menuColor: string
  menuFont: string
  menuList: { id: string; url: string; name: string }[]
  menuSize: string
  menuStyle: string
  socialsColor: string
  twitter: string
}

export interface PrefsFooter {
  twitter: string
  facebook: string
  instagram: string
  menuFont: string
  backgroundColor: string
  menuColor: string
  menuList: MenuList
  menuSize: string
  menuStyle: string
  socialsColor: string
  socialsTitle: string
  submenuColor: string
  submenuFont: string
  submenuSize: string
  submenuStyle: string
}

export interface ThemePageType {
  contentPageId: number | string
  css: Nullable<string>
  default: boolean
  form: string
  id: number
  name: string
  position: number
  prefs: {
    products: []
    tabSettingsEnabled: boolean
    favicon: string
    title: string
  }
  required: boolean
  slug: string
  themableId: number
  themableType: string
  createdAt: string
  updatedAt: string
}

interface MenuList {
  id: string
  name: string
  default: boolean
  subMenu?: {
    default: boolean
    id: string
    name: string
    url: string
  }[]
}

export const createMembershipApi = ({ GET }: ApiClient) => ({
  fetchItem: (id: string | number, data) => GET<Response<MembershipTheme>>(`${BASE_URL}/${id}`, data),
})

export type MembershipThemeApi = ReturnType<typeof createMembershipApi>
