import { makeAutoObservable } from 'mobx'
import { notify } from 'libs/common/notify'

import { apiClient } from 'utils/requests.utils'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'

import { BankAccountsApi, BankAccount, createBankAccountsApi } from '../api/bankAccounts.api'
import { WithFetchList, handleFetchFullList, handleFetchList } from './actions/fetch-list'
import { WithUpdateItem, handleUpdateItem } from './actions/update-item'
import { WithResetData, handleResetStore } from './actions/reset-data'
import { handlePaginationChange } from './actions/pagination-change'

export class BankAccountsStore implements WithResetData, WithFetchList<BankAccount>, WithUpdateItem<BankAccount> {
  declare api: BankAccountsApi
  list: BankAccount[] = []
  item: BankAccount = null
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }
  expands = null
  scopes = new Map()

  constructor() {
    makeAutoObservable(this)
    this.api = createBankAccountsApi(apiClient)
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setList(list: BankAccount[]) {
    this.list = list
  }

  setItem(data: BankAccount) {
    this.item = data
  }

  setScope(key, value) {
    this.scopes.set(key, value)
  }

  resetScopes = () => (this.scopes = new Map())

  setExpands = (value) => {
    this.expands = [...this.expands, ...value]
  }

  resetExpands = () => (this.expands = [])

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  resetData = () => handleResetStore(this)

  fetchFullList = async (data = {}) => await handleFetchFullList<BankAccount>(this, data)
  applyPagination = async (key, value) => await handlePaginationChange<BankAccount>(this, key, value)
  fetchList = async (data = {}) => await handleFetchList<BankAccount>(this, data)
  updateItem = async (id, data = {}) => await handleUpdateItem<BankAccount>(this, id, data)

  createItem = async (data) => {
    this.setLoading(true)
    const resp = await this.api.createItem(data)
    if (resp?.success) {
      this.fetchList()
    }
    this.setLoading(false)

    return resp
  }

  deleteItem = async (id: string | number) => {
    this.setLoading(true)
    const { success } = await this.api.deleteItem(id)
    if (success) {
      this.fetchList()
      notify('success', I18n.t('react.shared.notific.removed'))
    }
    this.setLoading(false)
  }

  get hasBankAccounts() {
    return !!this.list.length
  }
}
