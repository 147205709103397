import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import moment from 'moment'

import { ProfileTypes } from 'types/helpers'

import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloInput } from '@elo-ui/components/elo-inputs'
import { EloCheckbox } from '@elo-ui/components/elo-checkbox'

import { setCookies } from 'libs/common/cookies'
import { isProduction } from 'utils/env.utils'
import { validateUsername } from 'utils/validators.utils'
import { getAffiliateCookies } from 'utils/affiliatePrograms.utils'
import { pushToDataLayer, isDataLayerAvailable } from 'utils/GTMDataLayer.utils'

import { ACTIVE_PROFILES } from 'constants/profile.constants'
import { DATE_FORMATS } from '@elo-kit/constants/dateTime.constants'

import { notify } from 'libs/common/notify'

import { createProfile } from '../../../api/user.api'
import { usePayerStore } from '../../../hooks/use-payer-stores'

import './become-seller-modal.scss'

export const BecomeSellerModal = observer(() => {
  const { userStore } = usePayerStore()
  const { toggleBecomeSellerModal, becomeSellerModalOpen } = userStore
  const isBecomeSellerSignIn = userStore.isBecomeSellerSignIn()

  const [isAgreementAccepted, setAgreementAccepted] = useState(false)
  const [userName, setUserName] = useState('')
  const [userNameError, setUserNameError] = useState('')

  const toggleAgreementAccepted = () => {
    setAgreementAccepted(!isAgreementAccepted)
  }

  const handleUserNameChange = (value) => {
    setUserName(value)
  }

  useEffect(() => {
    const timeoutId = setTimeout(async () => {
      const value = await validateUsername(userName)
      setUserNameError(value)
    }, 500)
    return () => clearTimeout(timeoutId)
  }, [userName, 500])

  const submitForm = async () => {
    const requestData = {
      termsAccepted: true,
      profileType: ACTIVE_PROFILES.seller,
      username: (userName || '').toLowerCase(),
      ...getAffiliateCookies(),
    }

    const { success } = await createProfile(requestData)
    if (success) {
      const { profileTypes } = userStore.item
      const noSellerProfile = profileTypes.indexOf(ACTIVE_PROFILES.seller as ProfileTypes) < 0

      if (noSellerProfile && isDataLayerAvailable() && isProduction()) {
        const requestData = {
          event: 'segment_payer_flow',
          sign_up_date: moment().format(DATE_FORMATS.NOT_SEPARATED_YYYYMMDD),
        }
        pushToDataLayer(requestData)
      }

      toggleBecomeSellerModal()
      await setCookies('cabinet_profile_type', 'seller')
      notify('success', I18n.t('react.shared.notific.created'))
      window.location.href = `/cabinet${window.location.search}`
    }
  }
  const submitDisabled = true
  const label = I18n.t('react.shared.accept_terms.title', {
    terms_href: '/terms',
    privacy_href: '/privacy',
  })

  return (
    <EloModal isOpen={becomeSellerModalOpen} onClose={toggleBecomeSellerModal} className='become-seller-modal'>
      <EloModalHeader>
        {isBecomeSellerSignIn
          ? I18n.t('react.shared.become_seller_existing_user.title')
          : I18n.t('react.shared.become_seller')}
      </EloModalHeader>
      <EloModalBody>
        <div className='become-seller-modal__trial-period'>{I18n.t('react.shared.sign_up_form.trial_period')}</div>
        <EloInput
          errorText={userNameError}
          error={Boolean(userNameError)}
          value={userName}
          placeholder={I18n.t('react.shared.link')}
          label={I18n.t('react.shared.seller_modal.shop_link')}
          required
          onChange={(e) => handleUserNameChange(e.target.value)}
        />
        <div
          dangerouslySetInnerHTML={{ __html: I18n.t('react.shared.username_note') }}
          className='become-seller-modal__password-description'
        />

        <EloCheckbox checked={isAgreementAccepted} onChange={toggleAgreementAccepted}>
          <div dangerouslySetInnerHTML={{ __html: label }} />
        </EloCheckbox>
      </EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--end'>
        <EloButton variant='highlight' disabled={submitDisabled} onClick={() => submitForm}>
          {I18n.t('react.shared.button.create_seller_account')}
        </EloButton>
      </EloModalFooter>
    </EloModal>
  )
})
