import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'

const BASE_URL = (courseSessionId) => `/payer/course_sessions/${courseSessionId}/lessons`

export interface Lesson {
  id: string | number
  name: string
  static?: boolean
}

export const createLessonsApi = ({ GET, POST }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<Lesson[]>>(BASE_URL(data.courseSessionId), data),
  fetchItem: (id: string | number, data) => GET<Response<Lesson>>(`${BASE_URL(data.courseSessionId)}/${id}`, data),
  finishItem: (id: string | number, data) => POST<Response<Lesson>>(`${BASE_URL(data.courseSessionId)}/${id}/finish`),
})

export type LessonsApi = ReturnType<typeof createLessonsApi>
