import React, { useRef, useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloInfoMessage } from '@elo-ui/components/elo-info-message'
import { EloInfoIcon } from '@elo-ui/components/icons/regular'
import { EloInput } from '@elo-ui/components/elo-inputs'
import { EloSelect } from '@elo-ui/components/elo-select'
import { ReCaptchaField } from '@elo-kit/components/form/re-captcha-field/ReCaptchaField'
import { EloButton } from '@elo-ui/components/elo-button'

import { validators } from 'utils/validators.utils'
import { validateEmailUniqueness } from 'utils/validators-auth.utils'
import { profile } from 'utils/profileHelper.utils'

import { MAX_PROFILE_NAME_LENGTH, MIN_NAME_LENGTH, MIN_PASSWORD_LENGTH, PROFILES } from 'constants/profile.constants'
import { TIMEZONE_OPTIONS } from 'constants/timezones.constants'

interface EditProfileModalProps {
  toggleModal: () => void
  userProfileId: string
  userStore
  userProfilesStore
}

export const EditProfileModal: React.FC<EditProfileModalProps> = observer((props) => {
  const { toggleModal, userProfileId, userStore, userProfilesStore } = props
  const [timeZoneName, setTimeZoneName] = useState(userStore.item.timeZoneName)
  const [email, setEmail] = useState(userStore.item.email)
  const [password, setPassword] = useState('')
  const [publicName, setPublicName] = useState(userProfilesStore.item.publicName)
  const [isEmailUnique, setIsEmailUnique] = useState(true)

  const recaptchaRef = useRef(null)

  const isSeller = profile.profileType === PROFILES.seller
  const isPayer = profile.profileType === PROFILES.payer
  const notEqualEmail = email !== userStore.item.email

  const submitForm = async () => {
    if (isSeller || isPayer) {
      await userProfilesStore.updateItem(userProfileId, { publicName })
    }

    await userStore.updateItem({ timeZoneName })

    if (notEqualEmail) {
      recaptchaRef?.current?.executeAsync().then(async (res) => {
        const requestData = {
          email: email,
          currentPassword: password,
          recaptcha: res,
        }

        await userStore.updateEmail(requestData)

        toggleModal()
      })
    } else {
      toggleModal()
    }
  }

  const timeZoneValue = TIMEZONE_OPTIONS.find((option) => String(option.value) === String(timeZoneName))

  const selectClasses = classNames({
    'input-group-container--margin-bottom-24': notEqualEmail,
  })

  const isPasswordValid = password?.length >= MIN_PASSWORD_LENGTH
  const isEmailValid = validators.isEmail(email) && isEmailUnique
  const isPublicNameValid =
    !publicName || (publicName.length >= MIN_NAME_LENGTH && publicName.length <= MAX_PROFILE_NAME_LENGTH)

  const checkIsEmailUnique = async (email) => {
    if (email !== userStore.item.email && validators.isEmail(email)) {
      setIsEmailUnique(!(await validateEmailUniqueness(email)))
    }
  }

  const getEmailError = () => {
    if (!validators.isEmail(email)) {
      return I18n.t('react.shared.validations.invalid_email')
    }

    if (!isEmailUnique) {
      return I18n.t('react.shared.validations.email_exists')
    }
  }

  const submitDisabled =
    !timeZoneValue ||
    !email ||
    (notEqualEmail && (!password || !isPasswordValid)) ||
    !isEmailValid ||
    !isPublicNameValid

  return (
    <EloModal isOpen onClose={toggleModal}>
      <EloModalHeader>
        <div>{I18n.t('react.shared.edit_profile')}</div>
      </EloModalHeader>
      <EloModalBody>
        <div>
          {userStore.item.pendingReconfirm && (
            <div className='input-group-container--margin-bottom-24'>
              <EloInfoMessage icon={<EloInfoIcon />} status='information'>
                {I18n.t('react.shared.waiting_approval.unconfirmed_email', {
                  email: userStore.item.unconfirmedEmail || '',
                })}
              </EloInfoMessage>
            </div>
          )}

          <div className='input-group-container--margin-bottom-24'>
            <EloInput
              required
              value={email}
              onChange={(e) => {
                setEmail(e.target.value)
                checkIsEmailUnique(e.target.value)
              }}
              label={I18n.t('react.shared.email')}
              placeholder={I18n.t('react.shared.new_email')}
              hintText={!email && `${I18n.t('react.cabinet.profile.edit_email.current')} ${userStore.item.email}`}
              error={email && notEqualEmail && !isEmailValid}
              errorText={getEmailError()}
            />
          </div>

          {(isSeller || isPayer) && (
            <div className='input-group-container--margin-bottom-24'>
              <EloInput
                value={publicName}
                onChange={(e) => setPublicName(e.target.value)}
                label={I18n.t('react.shared.public_name')}
                tooltipOptions={{
                  content: I18n.t('react.cabinet.help_icon.public_name.content'),
                }}
                error={!isPublicNameValid}
                errorText={I18n.t('react.shared.validations.public_name', {
                  min: MIN_NAME_LENGTH,
                  max: MAX_PROFILE_NAME_LENGTH,
                })}
              />
            </div>
          )}

          <div className={selectClasses}>
            <EloSelect.Select
              required
              label={I18n.t('react.shared.timezone')}
              onChange={(option: { label: string; value: string }) => setTimeZoneName(option?.value)}
              options={TIMEZONE_OPTIONS}
              value={timeZoneValue}
              tooltipOptions={{
                content: I18n.t('react.shared.help_icon.profile_timezone.content'),
                title: I18n.t('react.shared.help_icon.profile_timezone.title'),
              }}
              useMenuPortal
            />
          </div>

          {notEqualEmail && (
            <EloInput
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={I18n.t('react.shared.confirm_with_password')}
              label={I18n.t('react.shared.password')}
              tooltipOptions={{
                content: I18n.t('react.cabinet.help_icon.confirm_password.content'),
              }}
              type='password'
              error={password && !isPasswordValid}
              errorText={I18n.t('react.shared.validations.password_too_short', { min: MIN_PASSWORD_LENGTH })}
            />
          )}

          <ReCaptchaField recaptchaRef={recaptchaRef} locale={I18n.currentLocale()} />
        </div>
      </EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--end'>
        <EloButton onClick={toggleModal} variant='secondary'>
          {I18n.t('react.shared.button.cancel')}
        </EloButton>
        <EloButton onClick={submitForm} disabled={submitDisabled}>
          {I18n.t('react.shared.confirm')}
        </EloButton>
      </EloModalFooter>
    </EloModal>
  )
})
