import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse } from 'types/responses'

const BASE_URL = (courseSessionId, lessonId) => `/payer/course_sessions/${courseSessionId}/lessons/${lessonId}/comments`

export const createCommentsApi = ({ GET, POST, DELETE, PATCH }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<any[]>>(BASE_URL(data.courseSessionId, data.lessonId), data),
  createItem: (data) => POST(BASE_URL(data.courseSessionId, data.lessonId), data),
  deleteItem: (id, data) => DELETE(`${BASE_URL(data.courseSessionId, data.lessonId)}/${id}`),
  updateItem: (id, data, params) =>
    PATCH(`${BASE_URL(data.courseSessionId, data.lessonId)}/${id}`, {
      ...data,
      ...params,
    }),
})

export type CommentsApi = ReturnType<typeof createCommentsApi>
