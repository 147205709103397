export const B_BASE = 1024
export const KB_BASE = B_BASE ** 2
export const MB_BASE = B_BASE ** 3
export const GB_BASE = B_BASE ** 4
export const TB_BASE = B_BASE ** 5

export const convertToBytes = (value: number, units = '') => {
  const base =
    {
      pb: TB_BASE,
      tb: GB_BASE,
      gb: MB_BASE,
      mb: KB_BASE,
      kb: B_BASE,
    }[units.toLowerCase()] || 1

  return value * base
}
