import { ApiClient } from 'utils/requests.utils'

import { PaginatedResponse } from 'types/responses'

const BASE_URL = '/payer/video_captions'

interface VideoCaptionsRequestData {
  contentBlockId: number
  lessonStatusId: number
  sortKey: string
  sortDir: 'asc' | 'desc'
}

export const createVideoCaptionsApi = ({ GET, PUT, POST }: ApiClient) => ({
  fetchList: (data: VideoCaptionsRequestData) => GET<PaginatedResponse<any[]>>(BASE_URL, data),
  createItem: (body: any) => POST<any>(BASE_URL, body),
  updateItem: (id: string | number, body: any) => PUT<any>(`${BASE_URL}/${id}/pass`, body),
})

export type VideoCaptionsApi = ReturnType<typeof createVideoCaptionsApi>
