import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'
import { createProductsApi, ProductsApi, Product } from '../api/products.api'

import { handleFetchFullList, handleFetchList, WithFetchList } from './actions/fetch-list'
import { handleFetchItem, WithFetchItem } from './actions/fetch-item'

export class ProductsStore implements WithFetchList<Product>, WithFetchItem<Product> {
  declare api: ProductsApi
  list: Product[] = []
  item: Product = {
    id: '',
    name: '',
  }
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }

  expands = []
  scopes = new Map()

  constructor() {
    this.api = createProductsApi(apiClient)
    makeAutoObservable(this)
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setItem(item: Product) {
    this.item = item
  }

  resetItem() {
    this.item = null
  }

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  resetExpands = () => (this.expands = [])

  setList(list: Product[]) {
    this.list = list
  }

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  setScope(key, value) {
    this.scopes.set(key, value)
  }

  fetchItem = async (id, data?) => await handleFetchItem<Product>(this, id, data)

  fetchFullList = async (data) => await handleFetchFullList(this, data)

  fetchList = async (data?) => await handleFetchList<Product>(this, data)
}
