import React from 'react'
import { observer } from 'mobx-react'

import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloWarningIcon } from '@elo-ui/components/icons/regular'

import { usePayerStore } from '../../../hooks/use-payer-stores'

import { MODALS_IDS } from '../index'

import './order-cancel-modal.scss'

interface OrderCancelModalProps {
  onSubmit: () => void
}

export const OrderCancelModal = observer(() => {
  const { modalsManager } = usePayerStore()
  const { isOpen, onSubmit } = modalsManager.getModal<OrderCancelModalProps>(MODALS_IDS.orderCancelModal)

  const handleClose = () => modalsManager.close(MODALS_IDS.orderCancelModal)
  const handleSubmit = () => {
    onSubmit()
    handleClose()
  }

  return (
    <EloModal className='order-cancel-modal' isOpen={isOpen} onClose={handleClose}>
      <EloModalHeader className='order-cancel-modal__header'>
        <span className='order-cancel-modal__header-icon'>
          <EloWarningIcon size={32} />
        </span>
        {I18n.t('react.payer.orders.cancel_subscription')}
      </EloModalHeader>
      <EloModalBody>{I18n.t('react.cabinet.orders.sure_you_want_to_cancel')}</EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--end'>
        <EloButton onClick={handleClose}>{I18n.t('react.shared.close')}</EloButton>
        <EloButton variant='danger' onClick={handleSubmit}>
          {I18n.t('react.payer.orders.complete_cancelation')}
        </EloButton>
      </EloModalFooter>
    </EloModal>
  )
})
