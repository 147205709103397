import { makeAutoObservable } from 'mobx'
import { ACCESS_LIMITED_MODAL_MODE } from 'constants/courses.constants'
import { PRODUCT_TYPE_IDS } from 'constants/products.constants'
import { ACCESS_SESSION_LIMIT } from 'constants/options.constants'

import { apiClient } from 'utils/requests.utils'
import { createSellablesApi, SellablesAPI } from '../api/sellables.api'
import { PayerRootStore } from './payerRoot.store'

export class ProductSessionStore {
  declare api: SellablesAPI
  root = null

  accessDeniedModalIsOpen = false
  showAccessLimitedModalMode = ACCESS_LIMITED_MODAL_MODE.anotherPersonViewing
  accessLimitIntervalId = null

  constructor(rootStore: PayerRootStore) {
    makeAutoObservable(this)
    this.root = rootStore
    this.api = createSellablesApi(apiClient)
  }

  setShowAccessLimitedModalMode = (mode) => (this.showAccessLimitedModalMode = mode)
  clearIntervalAccessLimitIntervalId = () => {
    clearInterval(this.accessLimitIntervalId)
    this.accessLimitIntervalId = null
  }
  toggleAccessDeniedModal = (flag) => (this.accessDeniedModalIsOpen = flag)
  closeAccessDeniedModal = (username, historyPush, sellableId, form) => {
    if (form === PRODUCT_TYPE_IDS.membership) {
      historyPush(`/payer/s/${username}/products/${sellableId}`)
    } else {
      historyPush(`/payer/s/${username}`)
    }
    this.toggleAccessDeniedModal(false)
  }

  isNeedCheckAccess = (username) => {
    const {
      item: { accessSessionLimit },
    } = this.root?.sellablesStore
    const currentSeller = this.root?.sellersStore.list.find((s) => s.username === username) || {}
    return (
      currentSeller.optionKeys?.includes(ACCESS_SESSION_LIMIT) && accessSessionLimit && !this.accessDeniedModalIsOpen
    )
  }

  checkAccess = (id) => this.api.checkAccess(id)
}
