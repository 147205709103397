import { DEFAULT_PAGE, PAGINATION_KEYS } from 'constants/pagination.constants'
import { handleFetchList, WithFetchList } from './fetch-list'

export type Pagination = {
  page?: number
  per?: number
  total?: number
}
export interface WithPagination {
  pagination: Pagination
  setPagination(pagination: Pagination): void
}

export async function handlePaginationChange<I>(store: WithFetchList<I>, key: string, value: number) {
  let paginationValue = value

  if (key === PAGINATION_KEYS.page) {
    const pageCount = store.pagination.total ? Math.ceil(store.pagination.total / store.pagination.per) : DEFAULT_PAGE
    if (value > pageCount) paginationValue = pageCount
    if (value < DEFAULT_PAGE) paginationValue = DEFAULT_PAGE
  }

  store.setPagination({
    [key]: paginationValue,
    ...(key === PAGINATION_KEYS.per && { page: DEFAULT_PAGE }),
  })

  await handleFetchList<I>(store)
}
