import React from 'react'

import { EloCloseIcon, EloListIcon } from '@elo-ui/components/icons/regular'

interface Props {
  isCollapsed: boolean
  onToggle: () => void
}

export const EloSidebarButton: React.FC<Props> = ({ isCollapsed, onToggle }) => (
  <button onClick={onToggle} className='elo-sidebar__button'>
    {isCollapsed ? <EloListIcon /> : <EloCloseIcon />}
  </button>
)
