import { makeAutoObservable } from 'mobx'

import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'
import { apiClient } from 'utils/requests.utils'
import { createCoversApi, CoversApi } from '../api/covers.api'
import { handleResetStore } from './actions/reset-data'
import { handleFetchItem, WithFetchItem } from './actions/fetch-item'
import { handleFetchList, WithFetchList } from './actions/fetch-list'
import { handleApplyFilters, WithFilters } from './actions/filters'
import { handlePaginationChange } from './actions/pagination-change'

export class CoversStore implements WithFetchItem<any>, WithFetchList<any>, WithFilters<any> {
  declare api: CoversApi
  list: any[] = []
  item: any = {}
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }

  expands = []
  scopes = new Map()
  filters = new Map()

  constructor() {
    this.api = createCoversApi(apiClient)
    makeAutoObservable(this)
  }

  setLoading(value) {
    this.isLoading = value
  }

  setItem(item) {
    this.item = item
  }

  resetItem() {
    this.item = null
  }

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  resetExpands = () => (this.expands = [])

  setList(list) {
    this.list = list
  }

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  setFilter(key, value) {
    this.filters.set(key, value)
  }

  setScope(key, value) {
    this.scopes.set(key, value)
  }

  resetScopes() {
    this.scopes = new Map()
  }

  resetFilters() {
    this.filters = new Map()
  }

  fetchItem = async (id, data?) => await handleFetchItem<any>(this, id, data)

  fetchList = async (data?) => await handleFetchList<any>(this, data)

  applyFilters = async () => await handleApplyFilters(this)

  applyPagination = async (key, value) => await handlePaginationChange<any>(this, key, value)

  resetData = () => handleResetStore(this)

  createItem = async (data) => {
    this.setLoading(true)
    const resp = await this.api.createItem(data || this.item)
    this.setItem(resp)
    this.setLoading(false)
    return resp
  }

  deleteItem = async (id) => {
    this.setLoading(true)
    const resp = await this.api.deleteItem(id)
    this.setLoading(false)
    return resp
  }
}

export default new CoversStore()
