import React, { useState, useEffect } from 'react'
import { observer } from 'mobx-react'

import { isEmpty } from 'utils/lodash.utils'
import { notify } from 'libs/common/notify'

import { EloInput } from '@elo-ui/components/elo-inputs'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloSelect } from '@elo-ui/components/elo-select'
import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'

import { splitStreet } from 'utils/paymentSetting.utils'

import { bankAccountValidationSchema } from 'shared/screens/profile/userProfile.schema'

import { BankAccount, BankAccountFile } from '../../../api/bankAccounts.api'
import { usePayerStore } from '../../../hooks/use-payer-stores'

import { FileUploadButton } from '../../common/file-upload-button'

import { MODALS_IDS } from '../index'

import './bank-account-modal.scss'

interface BankAccountModalProps {
  bankAccount: BankAccount
}

const DEFAULT_FIELDS = {
  city: '',
  countryCode: '',
  bic: '',
  holderName: '',
  iban: '',
  region: '',
  street: '',
  streetNumber: '',
  zip: '',
}

const DEFAULT_USER_PROFILE = {
  city: '',
  fullName: '',
  zip: '',
}

export const BankAccountModal: React.FC = observer(() => {
  const { countriesStore, bankAccountsStore, payerStore, modalsManager } = usePayerStore()
  const { isOpen, bankAccount } = modalsManager.getModal<BankAccountModalProps>(MODALS_IDS.bankAccountModal)

  const [errors, setErrors] = useState(DEFAULT_FIELDS)
  const [fields, setFields] = useState(DEFAULT_FIELDS)
  const [file, setFile] = useState<BankAccountFile | null>(null)

  const handleChangeField = async (name, value) => {
    const currentValue = typeof value === 'object' ? value.value : value

    setFields({ ...fields, [name]: currentValue })
    setErrors({ ...errors, [name]: '' })

    try {
      await bankAccountValidationSchema.validateAt(name, { [name]: currentValue })
    } catch (error: any) {
      if (error) {
        setErrors({ ...errors, [name]: error?.errors[0] })
      }
    }
  }

  useEffect(() => {
    const isEdit = !!bankAccount?.id
    const initialValues = !isEdit ? getInitialValuesFromProfile() : getInitialDataFromBankAccount()
    setFields(initialValues)

    if (isEdit) {
      setFile(bankAccount?.file)
    }

    if (!countriesStore.list.length) {
      countriesStore.fetchList()
    }

    return () => {
      setFile(null)
    }
  }, [bankAccount?.id])

  const onFileUpload = (file) => {
    if (!!file) {
      setFile(file)
    }
  }

  const handleClose = () => {
    setFields(DEFAULT_FIELDS)
    setErrors(DEFAULT_FIELDS)
    modalsManager.close(MODALS_IDS.bankAccountModal)
  }

  const submitForm = async () => {
    const { city, countryCode: country, bic, holderName, iban, region, street, streetNumber, zip } = fields

    const bankData = {
      bic,
      holderName,
      iban,
      file: file,
      holderAddress: {
        address: `${street || ''} ${streetNumber || ''}`,
        city,
        country,
        region,
        zip,
      },
    }

    const apiCall = () => {
      if (bankAccount?.id) {
        return bankAccountsStore.updateItem(bankAccount?.id, bankData)
      }

      return bankAccountsStore.createItem(bankData)
    }

    const { success } = await apiCall()

    if (success) {
      await bankAccountsStore.fetchFullList()
    }

    handleClose()
  }

  const getInitialValuesFromProfile = () => {
    const { item: userProfile } = payerStore
    const { city, fullName, zip } = userProfile || DEFAULT_USER_PROFILE
    return {
      city,
      bic: '',
      holderName: fullName,
      iban: '',
      streetNumber: '',
      region: '',
      zip,
      countryCode: '',
      street: '',
    }
  }

  const getInitialDataFromBankAccount = () => {
    const { bic = '', holderAddress, holderName = '', iban = '' } = bankAccount || ({} as BankAccount)
    const { address = '', city = '', country = '', region = '', zip = '' } = holderAddress
    const { streetName = '', streetNumber = '' } = splitStreet(address)
    return {
      city,
      bic,
      holderName,
      iban,
      streetNumber,
      region,
      zip,
      countryCode: country,
      street: streetName,
    }
  }

  const onFileUploadError = (error) => {
    notify('error', error.message)
  }

  const someFieldIsEmpty = Object.values(fields).some((field) => isEmpty(field))
  const hasErrors = Object.values(errors).some((error) => !!error)
  const isDisabled = someFieldIsEmpty || hasErrors || file === null

  return (
    <EloModal isOpen={isOpen} isOverlayFixed className='bank-account-modal' onClose={handleClose}>
      <EloModalHeader>{I18n.t('react.shared.bank_account')}</EloModalHeader>
      <EloModalBody>
        <EloInput
          name='holderName'
          label={I18n.t('react.shared.full_name')}
          value={fields.holderName}
          onChange={(e) => handleChangeField('holderName', e.target.value)}
          required={true}
          error={!!errors?.holderName}
          errorText={errors?.holderName}
          tooltipOptions={{
            title: I18n.t('react.cabinet.help_icon.company_name.title'),
            content: I18n.t('react.cabinet.help_icon.company_name.content'),
            placement: 'right',
          }}
        />
        <div className='bank-account-modal__input-group-1'>
          <EloInput
            name='street'
            label={I18n.t('react.shared.street')}
            value={fields.street}
            onChange={(e) => handleChangeField('street', e.target.value)}
            required={true}
            error={!!errors?.street}
            errorText={errors?.street}
          />
          <EloInput
            name='streetNumber'
            label={I18n.t('react.shared.street_number')}
            value={fields.streetNumber}
            onChange={(e) => handleChangeField('streetNumber', e.target.value)}
            required={true}
            error={!!errors?.streetNumber}
            errorText={errors?.streetNumber}
          />
        </div>
        <div className='bank-account-modal__input-group-2'>
          <EloInput
            name='zip'
            label={I18n.t('react.shared.zip')}
            value={fields.zip}
            onChange={(e) => handleChangeField('zip', e.target.value)}
            required={true}
            error={!!errors?.zip}
            errorText={errors?.zip}
          />
          <EloInput
            name='city'
            label={I18n.t('react.shared.city')}
            value={fields.city}
            onChange={(e) => handleChangeField('city', e.target.value)}
            required={true}
            error={!!errors?.city}
            errorText={errors?.city}
          />
        </div>
        <div className='bank-account-modal__input-group-3'>
          <EloSelect.Select
            label={I18n.t('react.shared.country')}
            onChange={(value) => handleChangeField('countryCode', value)}
            onBlur={() => handleChangeField('countryCode', fields.countryCode)}
            options={countriesStore.countrySelectOptions}
            value={fields.countryCode ? countriesStore.getCountrySelectOption(fields.countryCode) : null}
            error={!!errors?.countryCode}
            errorText={errors?.countryCode}
            required
          />
          <EloInput
            name='region'
            label={I18n.t('react.shared.region')}
            value={fields.region}
            onChange={(e) => handleChangeField('region', e.target.value)}
            required={true}
            error={!!errors?.region}
            errorText={errors?.region}
          />
        </div>
        <EloInput
          name='iban'
          label={I18n.t('react.shared.iban')}
          value={fields.iban}
          onChange={(e) => handleChangeField('iban', e.target.value)}
          error={!!errors?.iban}
          errorText={errors?.iban}
          required
        />
        <EloInput
          name='bic'
          label={I18n.t('react.shared.bic')}
          value={fields.bic}
          onChange={(e) => handleChangeField('bic', e.target.value)}
          error={!!errors?.bic}
          errorText={errors?.bic}
          required
        />
        <FileUploadButton
          label={I18n.t('react.shared.account_verification')}
          description={I18n.t('react.shared.cashout_verification_description')}
          buttonText={file?.name || I18n.t('react.shared.upload_file')}
          onUpload={onFileUpload}
          onError={onFileUploadError}
          required={true}
        />
      </EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--end'>
        <EloButton variant='secondary' onClick={handleClose}>
          {I18n.t('react.shared.button.cancel')}
        </EloButton>
        <EloButton disabled={isDisabled} onClick={submitForm}>
          {I18n.t('react.shared.button.save')}
        </EloButton>
      </EloModalFooter>
    </EloModal>
  )
})
