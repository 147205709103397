import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'
import { Nullable, ProductType } from 'types/helpers'

const BASE_URL = '/payer/orders'

export interface Order {
  id: number
  state: string
  activatedAt: string
  additionalId: OrderAdditionalId
  affiliateLifetime: boolean
  affiliateNodeId: number
  bankData: Record<string, unknown>
  campaignId: Nullable<unknown>
  canContinue: boolean
  cancelAt: Nullable<string>
  cancelerId: Nullable<number>
  cancelerType: Nullable<string>
  cancellationTermId: Nullable<number>
  cancellationTermPrefs: OrderCancellationTermPrefs
  chargesCount: number
  clickerRef: Nullable<unknown>
  couponId: Nullable<number>
  couponPrefs: OrderCouponPref
  creditCardId: Nullable<number>
  currencyId: number
  customEmailSentAt: Nullable<string>
  data: OrderData
  edited: boolean
  errorData: OrderErrorData
  fromZapier: boolean
  funnelId: number
  funnelNodeId: Nullable<number>
  futureRate: Record<string, unknown>
  giftComment: Nullable<string>
  iban: Nullable<string>
  issued: Nullable<unknown>
  lastChargeAttempt: number
  ownerId: number
  pageProductId: number
  payerData: OrderPayerData
  payerId: number
  paymentForm: string
  paymentSettingId: number
  paymentState: string
  periodType: string
  postProcessed: boolean
  postsellPageVisited: boolean
  productId: number
  provider: string
  providerData: OrderProviderData
  publisherId: number
  relatedOrderId: Nullable<number>
  resellerId: Nullable<number>
  sellerId: number
  toSplit: boolean
  token: string
  tracked: boolean
  upsellType: string
  vatData: OrderVatData
  wasEdited: boolean
  createdAt: string
  updatedAt: string
  nextChargeDate: string
  appliedVatChange: OrderVatChanged
  product?: Product
  testPeriodPassed: boolean
  withTestPeriod: boolean
  testPeriodPassedDaysAgo: number
  sellables?: any
  cancellable: boolean
  invoices: Invoice[]
  params: any
  nextChargeAmount: number
  paidCount: number
  payer: Payer
  rates: Rate[]
  lifetime?: boolean
  courses: Course[]
  seller: {
    username: string
  }
}

export interface SingleOrder {
  paymentState: string
  seller: {
    username: string
  }
  nextChargeDate: string
  nextChargeAmount: number
  cancelAt: Nullable<string>
  currencyId: number
  paymentForm: string
  provider: string
  periodType: string
  paidCount: number
  payer: {
    email: string
    fullName: string
    address: string
  }
  payerData: OrderPayerData
  product: Product
  invoices: Invoice[]
  rates: Rate[]
  products: Product[]
  testPeriodPassed: boolean
  withTestPeriod: boolean
  testPeriodPassedDaysAgo: Nullable<string>
  lifetime: boolean
  params?: {
    hide_next_charge_date?: string
    hide_payments_count?: string
    hide_period_type?: string
    hide_paymethod?: string
    hide_payment_state?: string
  }
  id: number
  cancellable: boolean
  token: string
}

export interface Course {
  id: string | number
  notifyAboutDripIn: boolean
  subscribedTill: Nullable<string>
}

interface Invoice {
  id: number
  state: string
  token?: string
  number: string | number
  deadline: Nullable<string>
  total: number
  created: string
  createdAt: string
  downloadLink: string
  paymentLink: string
  url: string
  pricingPlans?: any
}

interface Payer {
  id: number
  fullName: string
  email: string
  countryCode: string
  country: {
    code: string
  }
  address: string
}

interface Rate {
  id: number
  rate: number
  scheduled: string
  successDate: string
  state: string
  total: number
  invoiceNumber: number
  invoiceUrl: string
  paymethod: string
  transfers: RateTransfer[]
  orderRatePrices: OrderRatePrice[]
}

interface OrderRatePrice {
  id: number
  sellableId: number
  data: {
    cfgs: {
      isBusiness: boolean
      withVatNo: boolean
      mustPayVat: boolean
      calcsVatRate: Nullable<number>
      privateVatRate: number
      privateVatText: string
      vatBaseCountry: string
      businessVatRate: number
      businessVatText: string
      transferVatRate: number
    }
    rate: {
      net: number
      vat: number
      count: number
      gross: number
    }
    discount?: number
    fees?: number
    vatSum: number
    toPaySum: number
  }
}

interface RateTransfer {
  id: number
  total: number
  updatedAt: string
  idForSeller: number
  state: string
  transferableType: string
  form: string
}

interface OrderVatChanged {
  id: number
  payerDecision: string
  productsStat: {
    nextToPaySum: {
      after: number
      before: number
    }
  }
}

interface OrderAdditionalId {
  addId1: Nullable<unknown>
  addId2: Nullable<unknown>
}

interface OrderCancellationTermPrefs {
  form: Nullable<unknown>
  value: string
  period: number
  sendInEmail: boolean
  checkboxVisible: boolean
}

interface OrderCouponPref {
  id: number
  code: string
  name: string
  limit: Nullable<number>
  value: string
  active: boolean
  upsells: boolean
  maxCount: Nullable<number>
  recurring: boolean
  sellerId: number
  applyForm: string
  calcsForm: string
  validFrom: Nullable<unknown>
  validTill: Nullable<unknown>
  valueForm: string
  timeZoneName: Nullable<string>
  additionalFees: boolean
  affiliateNodeId: Nullable<number>
  couponsProductsAttributes: OrderCouponPrefAttr[]
}

interface OrderCouponPrefAttr {
  ticketId: Nullable<unknown>
  productId: number
  pricingPlanId: Nullable<unknown>
}

interface OrderData {
  sku: Nullable<unknown>
}

interface OrderErrorData {
  msg: string
  code: string
}

interface OrderPayerData {
  formType: string
  attendeeEmail: boolean
  userAttributes: OrderUserAttributes
  formTypeForVat: string
  userProfileAttributes: OrderUserProfileAttributes
}

interface OrderUserAttributes {
  email: string
  timeZoneName: string
  emailConfirmation: string
}

interface OrderUserProfileAttributes {
  zip: string
  city: string
  phone: string
  street: string
  vatNo: string
  company: string
  lastName: string
  firstName: string
  countryCode: string
  streetNumber: string
}

interface OrderProviderData {
  ipAddress: string
  browserInfo?: OrderProviderBrowserInfo
  mangoPayUser?: string
  mangoPayWallet?: string
  recurringPyInRegistration?: string
  stripeCustomer?: string
}

interface OrderProviderBrowserInfo {
  language: string
  userAgent: string
  colorDepth: string
  javaEnabled: boolean
  screenWidth: string
  acceptHeader: string
  screenHeight: string
  timeZoneOffset: string
  javaScriptEnabled: boolean
}

interface OrderVatData {
  name: string
  dirty: boolean
  valid: boolean
  vatNumber: string
  countryCode: string
}

export interface Product {
  id?: number
  accessPassword?: string
  pricingPlans?: any
  slug?: string
  courseThemeId?: number
  private?: boolean
  shortDescription?: string
  description?: string
  metaTitle?: string
  name?: string
  metaTags?: string
  metaDescription?: string
  covers?: {
    type?: string
    cover?: string
    url?: string
  }[]
  displayPrice?: number
  displayCurrencyId?: number
  form?: ProductType
  paymentPageTemplateId?: number
  position?: number
  tickets?: any
  redirectType?: string
  seller: any
  cover?: string
  membershipSession?: {
    notifyAboutDripIn: boolean
    subscribedTill: Nullable<string>
    id: number
  }
  courseSession?: {
    notifyAboutDripIn: boolean
    subscribedTill: Nullable<string>
    id: number
  }
}

export const createOrdersApi = ({ GET, POST }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<Order[]>>(BASE_URL, data),
  fetchItem: (id: string | number, data) => GET<Response<Order>>(`${BASE_URL}/${id}`, data),
  cancelOrder: (orderId) => POST(`${BASE_URL}/${orderId}/cancel`),
  continueOrder: (orderId) => POST(`${BASE_URL}/${orderId}/continue`),
})

export type OrdersApi = ReturnType<typeof createOrdersApi>
