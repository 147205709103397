import * as Yup from 'yup'
import IBAN from 'iban'

import {
  createValidationSchema,
  getRequiredObjectField,
  getRequiredStringField,
  setRequiredStringFields,
  getValidFirstNameField,
  getValidLastNameField,
} from 'utils/validators.utils'

import { isEqualCH } from 'utils/countries.utils'

import {
  BIC_REGEX,
  EXCLUDE_DOT_REGEX,
  EXCLUDE_WHITE_SPACE_REGEX,
  DDMMYYYY_REGEX,
} from '@elo-kit/constants/regex.constants'
import {
  HOLDER_ADDRESS_FIELDS,
  LEGAL_REPRESENTATIVE_USER_PROFILE_FIELDS,
  UBO_USER_PROFILE_FIELDS,
  HEAD_QUARTER_USER_PROFILE_COMMON_FIELDS,
  HEAD_QUARTER_USER_PROFILE_EXTENDED_FIELDS,
} from 'constants/paymentSetting.constants'
import { MP_REGION_REQUIRED_COUNTRIES } from 'constants/countries.constants'
import { MAX_COMPANY_NAME_LENGTH } from 'constants/profile.constants'

const commonSchemaFields = {
  firstName: getValidFirstNameField(),
  lastName: getValidLastNameField(),
  ...setRequiredStringFields(['city', 'street', 'zip', 'phone']),
  region: Yup.string().test('region-is-valid', I18n.t('react.shared.validations.required'), function (region) {
    const { payerCountry } = this.options.parent
    const { alpha2 } = payerCountry?.value || {}

    if (!region && payerCountry && MP_REGION_REQUIRED_COUNTRIES.includes(alpha2)) {
      return false
    }

    return true
  }),
}

const getBirthdayValidation = (base) => base.matches(DDMMYYYY_REGEX, I18n.t('react.shared.validations.birthday'))

const payerCountryLegitimationValidation = getRequiredObjectField().test(
  'country-is-valid',
  I18n.t('react.shared.validations.invalid_country'),
  (payerCountry) => {
    const { alpha2, euMember } = payerCountry.value
    return euMember || isEqualCH(alpha2)
  }
)

export const lemonwayLegitimationProfileSchema = createValidationSchema({
  ...commonSchemaFields,
  ...setRequiredStringFields(['nationality', 'phone']),
  birthday: getBirthdayValidation(getRequiredStringField()),
  payerCountry: payerCountryLegitimationValidation,
})

export const sellerProfileSchema = createValidationSchema({
  ...commonSchemaFields,
  birthday: getBirthdayValidation(Yup.string()),
  payerCountry: getRequiredObjectField(),
})

export const payerProfileSchema = createValidationSchema({
  ...commonSchemaFields,
  salutation: Yup.string(),
  vatPayer: Yup.string(),
  payerCountry: getRequiredStringField(),
  state: getRequiredStringField(),
})

export const getHeadQuarterValidationSchema = ({ isBusiness, isNewLegitimation, isSoleTrader }) =>
  createValidationSchema({
    ...setRequiredStringFields(
      isSoleTrader ? HEAD_QUARTER_USER_PROFILE_COMMON_FIELDS : HEAD_QUARTER_USER_PROFILE_EXTENDED_FIELDS
    ),
    region: getRequiredStringField(),
    vatPayer: Yup.boolean(),
    ...(isNewLegitimation
      ? {
          termsAccepted: Yup.boolean()
            .required(I18n.t('react.shared.validations.required'))
            .oneOf([true], I18n.t('react.shared.validations.required')),
        }
      : {}),
    ...(isBusiness
      ? {
          companyNumber: getRequiredStringField()
            .matches(EXCLUDE_WHITE_SPACE_REGEX, I18n.t('react.shared.validations.no_whitespaces'))
            .matches(EXCLUDE_DOT_REGEX, I18n.t('react.shared.validations.no_dots')),
          company: Yup.string()
            .required(I18n.t('react.shared.validations.required'))
            .max(
              MAX_COMPANY_NAME_LENGTH,
              I18n.t('react.shared.validations.profile_name', { max: MAX_COMPANY_NAME_LENGTH })
            ),
        }
      : {}),
  })

const getMangoPayProfileValidationSchema = (fields) =>
  createValidationSchema({
    ...setRequiredStringFields(fields),
    birthday: getBirthdayValidation(getRequiredStringField()),
  })

export const legalRepresentativeValidationSchema = getMangoPayProfileValidationSchema(
  LEGAL_REPRESENTATIVE_USER_PROFILE_FIELDS
)
export const uboValidationSchema = getMangoPayProfileValidationSchema(UBO_USER_PROFILE_FIELDS)

const createBankAccountValidationSchema = () =>
  createValidationSchema({
    ...setRequiredStringFields(HOLDER_ADDRESS_FIELDS),
    holderName: getRequiredStringField(),
    iban: getRequiredStringField().test(
      'iban-is-valid',
      I18n.t('react.shared.validations.invalid_iban'),
      (iban) => !iban || IBAN.isValid(iban)
    ),
    bic: getRequiredStringField().test(
      'bic-is-valid',
      I18n.t('react.shared.validations.invalid_bic'),
      (bic) => !bic || BIC_REGEX.test(bic)
    ),
  })

export const bankAccountValidationSchema = createBankAccountValidationSchema()
export const bankAccountLegitimationValidationSchema = createBankAccountValidationSchema()
