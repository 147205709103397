import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'
import { Nullable } from 'types/helpers'

const BASE_URL = '/payer/sellers'

export interface Seller {
  id: string | number
  username: Nullable<string>
  fullName: string
  publicName: Nullable<string>
  avatarUrl: Nullable<string>
  optionKeys: string[]
  domainName: string
  domainLogo: string
  membershipThemeId: number
  chatBody?: any
}

export const createSellersApi = ({ GET }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<Seller[]>>(BASE_URL, data),
  fetchItem: (id: string | number, data) => GET<Response<Seller>>(`${BASE_URL}/${id}`, data),
})

export type SellersApi = ReturnType<typeof createSellersApi>

export const defaultSeller = {
  id: null,
  optionKeys: [],
  username: null,
  fullName: null,
  avatarUrl: null,
  publicName: null,
  domainLogo: '',
  domainName: '',
  membershipThemeId: null,
}
