import React, { useState, useEffect } from 'react'
import classnames from 'classnames'
import { notify } from 'libs/common/notify'
import { toJS } from 'mobx'
import { observer } from 'mobx-react'

import { getCookies, setCookies } from 'libs/common/cookies'
import { isEmpty } from 'utils/lodash.utils'

import { MIN_PASSWORD_LENGTH, NUMBER_COUNT_IN_PASS, SPECIAL_CHARACTER_COUNT_IN_PASS } from 'constants/profile.constants'

import { passwordRules, validatePassword } from 'utils/validators-auth.utils'

import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloInput } from '@elo-ui/components/elo-inputs'
import { EloInfoMessage } from '@elo-ui/components/elo-info-message'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloStepper } from '@elo-ui/components/elo-stepper'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import { EloWarningCircleIcon } from '@elo-ui/components/icons/regular'

import { usePayerStore } from '../../../hooks/use-payer-stores'
import { SETUP_PASSWORD_TYPE, CHANGE_PASSWORD_TYPE } from '../../../constants/passwordModal'
import { MODALS_IDS } from '../index'

import './pasword-modal.scss'

const LEVELS_OF_COMPLEXITY = {
  1: I18n.t('react.shared.password_complexity.very_weak'),
  2: I18n.t('react.shared.password_complexity.weak'),
  3: I18n.t('react.shared.password_complexity.good'),
  4: I18n.t('react.shared.password_complexity.strong'),
}

const TooltipRulesList = ({ rules = [] }) => (
  <div className='complexity-rules'>
    <div className={classnames('complexity-rules__length', { active: rules.includes('length') })}>
      {I18n.t('react.shared.password_complexity.pass_length', { length: MIN_PASSWORD_LENGTH })}
    </div>
    <div className={classnames('complexity-rules__numbers', { active: rules.includes('numbers') })}>
      {I18n.t('react.shared.password_complexity.number_in_pass', { number: NUMBER_COUNT_IN_PASS })}
    </div>
    <div className={classnames('complexity-rules__symbols', { active: rules.includes('symbols') })}>
      {I18n.t('react.shared.password_complexity.special_characters', { number: SPECIAL_CHARACTER_COUNT_IN_PASS })}
    </div>
    <div className={classnames('complexity-rules__case', { active: rules.includes('upperLowerCase') })}>
      {I18n.t('react.shared.password_complexity.upper_lower_case')}
    </div>
  </div>
)

interface PasswordModalProps {
  type: 'setup' | 'change'
}

export const PasswordModal: React.FC = observer(() => {
  const { userStore, modalsManager } = usePayerStore()
  const { item: user } = userStore

  const { isOpen, type = SETUP_PASSWORD_TYPE } = modalsManager.getModal<PasswordModalProps>(MODALS_IDS.passwordModal)

  const [isTouchedPassword, setIsTouchedPassword] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [oldPassword, setOldPassword] = useState('')
  const [oldPasswordError, setOldPasswordError] = useState(false)
  const [complexityValidation, setComplexityValidation] = useState({
    confirmedRules: [],
    isPasswordValid: false,
    levelText: '',
  })

  const handleClose = () => {
    modalsManager.close(MODALS_IDS.passwordModal)
  }

  const setupPassword = (user) => {
    if (user.noPassword && !getCookies('setup_password_shown')) {
      modalsManager.open(MODALS_IDS.passwordModal, {
        type: SETUP_PASSWORD_TYPE,
      })
      setCookies('setup_password_shown', true)
    }
  }

  useEffect(() => {
    if (type === SETUP_PASSWORD_TYPE && !isEmpty(toJS(user))) {
      setupPassword(user)
    }
  }, [])

  const handlePasswordChange = (e) => {
    const passwordValue = e.target.value
    const complexity = validatePassword(passwordValue, passwordRules)

    setPassword(passwordValue)
    setComplexityValidation({
      levelText: LEVELS_OF_COMPLEXITY[complexity.confirmedRules.length],
      ...complexity,
    })
  }

  const handlePasswordConfirmChange = (e) => {
    const passwordValue = e.target.value

    setPasswordConfirm(passwordValue)
  }

  const handleOldPasswordChange = (e) => {
    const oldPasswordValue = e.target.value

    setOldPassword(oldPasswordValue)
  }

  const submitForm = async () => {
    const requestData = {
      password: password,
      passwordConfirmation: passwordConfirm,
      ...(type === CHANGE_PASSWORD_TYPE && { currentPassword: oldPassword }),
    }

    const { success } = await userStore.updateItem(requestData)

    if (success) {
      notify('success', I18n.t('react.app.form.password_changed'))
      handleClose()
    } else {
      setOldPasswordError(true)
    }
  }

  const activeSteps = complexityValidation.confirmedRules.length
  // @ts-ignore
  const completedSteps = activeSteps === 4 ? [...Array(activeSteps).keys()] : []
  // @ts-ignore
  const errorSteps = activeSteps < 4 ? [...Array(activeSteps).keys()] : []
  const passwordError = isTouchedPassword && (!complexityValidation.isPasswordValid || !password)
  const passwordConfirmError = passwordConfirm !== password
  const legacyPasswordError = type === CHANGE_PASSWORD_TYPE ? oldPasswordError || !oldPassword : false
  const submitDisabled = passwordError || passwordConfirmError || !isTouchedPassword || legacyPasswordError

  return (
    <EloModal className='password-modal' isOpen={isOpen} onClose={handleClose}>
      <EloModalHeader className='elo-ui-modal__header--center'>
        {type === SETUP_PASSWORD_TYPE ? I18n.t('react.shared.setup_password') : I18n.t('react.shared.change_password')}
      </EloModalHeader>
      <EloModalBody>
        {type === SETUP_PASSWORD_TYPE ? (
          <EloInfoMessage icon={<EloWarningCircleIcon />} size='small' status='warning'>
            {I18n.t('react.cabinet.profile.edit_pass.no_password')}
          </EloInfoMessage>
        ) : (
          <div className='password-modal__existing-password'>
            <EloInput
              type='password'
              placeholder={I18n.t('react.shared.current_password')}
              label={I18n.t('react.shared.enter_current_password')}
              onChange={(e) => handleOldPasswordChange(e)}
              onFocus={() => {
                setOldPasswordError(false)
              }}
              errorText={I18n.t('react.shared.validations.password_is_invalid')}
              error={oldPasswordError}
            />
          </div>
        )}
        <EloInput
          label={I18n.t('react.shared.new_password')}
          placeholder={I18n.t('react.shared.enter_new_password')}
          type='password'
          onFocus={() => {
            setIsTouchedPassword(true)
          }}
          onChange={(e) => handlePasswordChange(e)}
          error={passwordError}
        />
        {isTouchedPassword && (
          <>
            <EloStepper
              activeStep={null}
              setActiveStep={() => {}}
              steps={4}
              completedSteps={completedSteps}
              erroredSteps={errorSteps}
            />
          </>
        )}
        <div className='elo-stepper__complexity'>
          <EloTooltip
            className='password-modal__tooltip'
            content={<TooltipRulesList rules={complexityValidation.confirmedRules} />}
            placement='left'
          >
            <span className={classnames('elo-stepper__info', { active: completedSteps.length })}>
              {complexityValidation.levelText}
            </span>
          </EloTooltip>
        </div>
        <EloInput
          label={I18n.t('react.shared.re_enter_new_password')}
          placeholder={I18n.t('react.shared.re_enter_new_password')}
          type='password'
          onChange={(e) => handlePasswordConfirmChange(e)}
          error={passwordConfirmError}
          errorText={I18n.t('react.shared.validations.not_identical')}
        />
      </EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--center'>
        <EloButton variant='secondary' onClick={handleClose}>
          {I18n.t('react.shared.button.cancel')}
        </EloButton>
        <EloButton variant='highlight' onClick={submitForm} disabled={submitDisabled}>
          {I18n.t('react.shared.button.save')}
        </EloButton>
      </EloModalFooter>
    </EloModal>
  )
})
