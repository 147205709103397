import { makeAutoObservable } from 'mobx'

import { apiClient } from 'utils/requests.utils'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'

import { UserProfilesApi, Profile, createUserProfilesApi } from '../api/userProfiles.api'
import { WithFetchItem, handleFetchItem } from './actions/fetch-item'
import { WithFetchList, handleFetchFullList } from './actions/fetch-list'
import { WithResetData, handleResetStore } from './actions/reset-data'

export class UserProfilesStore implements WithResetData, WithFetchItem<Profile>, WithFetchList<Profile> {
  declare api: UserProfilesApi
  list: Profile[] = []
  item: Profile
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }
  expands = null
  scopes = new Map()

  constructor() {
    makeAutoObservable(this)
    this.api = createUserProfilesApi(apiClient)
  }

  setLoading(value) {
    this.isLoading = value
  }

  setItem(item: Profile) {
    this.item = item
  }

  resetItem() {
    this.item = null
  }

  setList(list: Profile[]) {
    this.list = list
  }

  setExpands = (value) => {
    this.expands = [...this.expands, ...value]
  }

  resetExpands = () => (this.expands = [])

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  resetData = () => handleResetStore(this)

  fetchItem = (id, data) => handleFetchItem<Profile>(this, id, data)

  createItem = async (data) => {
    this.setLoading(true)
    const response = await this.api.createItem(data)

    if (response.data && response.success) this.setItem(data)

    this.setLoading(false)

    return response
  }

  fetchFullList = async (data = {}) => await handleFetchFullList<Profile>(this, data)

  updateItem = async (id, data) => {
    this.setLoading(true)
    const response = await this.api.updateItem(id || this.item.id, data || this.item)

    if (response.data && response.success) this.setItem(data)

    this.setLoading(false)

    return response
  }

  getAvailableProfiles = () => {
    const isProfilesListEmpty = !this.list.length

    if (isProfilesListEmpty) return []

    return this.list.filter(({ profileTypesWithIds }) => {
      const keys = Object.keys(profileTypesWithIds)
      return !keys.length || keys.includes('payer')
    })
  }

  get hasProfiles() {
    return !!this.list.length
  }
}
