import { action, observable } from 'mobx'

import {
  getAvailableCurrencies,
  getCurrencyId,
  getCurrencyKey,
  getCurrencyUnit,
  convertToPrice,
  getCurrencySignPosition,
} from 'utils/currencies.utils'
import { CURRENCY_SIGNS } from 'constants/currencies.constants'
import { DEFAULT_FULL_LIST, DEFAULT_PAGE } from 'constants/pagination.constants'

import { apiClient } from 'utils/requests.utils'

import { CurrenciesApi, createCurrenciesApi } from '../api/currencies.api'

export class CurrenciesStore {
  declare api: CurrenciesApi

  @observable list: any[] = []

  constructor() {
    this.api = createCurrenciesApi(apiClient)
  }

  @action setList = (list: any[]) => (this.list = list)

  @action
  async fetchFullList(data = {}) {
    const resp = await this.api.fetchList({
      page: DEFAULT_PAGE,
      per: DEFAULT_FULL_LIST,
      ...data,
    })

    const { list = [] } = resp.data || {}
    this.setList(list)
  }

  getId = (key: string) => getCurrencyId(this.list, key)

  getKey = (id: number) => getCurrencyKey(this.list, id)

  getUnit = (id: number) => getCurrencyUnit(this.list, id)

  getCurrencySignPosition = (currencySign: CURRENCY_SIGNS) => getCurrencySignPosition(currencySign)

  convertToPrice = (value: string | number, currencyId: number | string, separator = '') =>
    convertToPrice(this.list, value || 0, currencyId, separator)

  filterAvailableCurrencies = ({
    paymentSystem,
    paymentForm,
    paymentProvider,
  }: Partial<{ paymentSystem: string; paymentForm: string; paymentProvider: string }> = {}) =>
    getAvailableCurrencies(this.list, paymentSystem, paymentForm, paymentProvider)
}

export default new CurrenciesStore()
