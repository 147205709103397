import { makeAutoObservable } from 'mobx'

import { notify } from 'libs/common/notify'

import { apiClient } from 'utils/requests.utils'
import { MembershipSessionsApi, createMembershipSessionApi } from '../api/membershipSessions.api'

export class MembershipSessionsStore {
  declare api: MembershipSessionsApi
  isLoading = false

  constructor() {
    makeAutoObservable(this)
    this.api = createMembershipSessionApi(apiClient)
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  dripInToggle = async (id: number | string) => {
    this.isLoading = true

    const resp = await this.api.dripInToggle(id)

    if (resp.success) {
      notify('success', I18n.t('react.cabinet.notific.success'))
    } else {
      notify('warning', I18n.t('react.cabinet.notific.warning'))
    }

    this.isLoading = false
  }
}
