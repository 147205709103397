import React from 'react'
import { EloLoadingSpinner } from '@elo-ui/components/elo-loading-spinner'

import './loading-router-fallback.scss'

export const LoadingRouterFallback = ({ type = '' }) => (
  <div className={`loading-fallback-container ${type}`}>
    <EloLoadingSpinner />
  </div>
)
