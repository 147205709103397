import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'
import { ProductGroupsApi, createProductGroupsApi, ProductGroup } from '../api/productGroups.api'
import { handleFetchFullList, handleFetchList, WithFetchList } from './actions/fetch-list'

export class ProductGroupsStore implements WithFetchList<ProductGroup> {
  declare api: ProductGroupsApi
  list: ProductGroup[] = []
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }

  expands = []
  scopes = new Map()

  constructor() {
    this.api = createProductGroupsApi(apiClient)
    makeAutoObservable(this)
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  resetExpands = () => (this.expands = [])

  setList(list: ProductGroup[]) {
    this.list = list
  }

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  setScope(key, value) {
    this.scopes.set(key, value)
  }

  fetchFullList = async (data) => await handleFetchFullList(this, data)

  fetchList = async (data?) => await handleFetchList<ProductGroup>(this, data)
}
