import React from 'react'

interface Props {
  link: string
  logo: string | React.ReactNode
}

export const EloSidebarLogo: React.FC<Props> = ({ link, logo }) => (
  <a href={link} className='elo-sidebar__logo'>
    {typeof logo === 'string' ? <img src={logo} alt='brand logo' /> : logo}
  </a>
)
