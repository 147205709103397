import React from 'react'
import { observer } from 'mobx-react'

import { EditProfileModal as LegacyEditProfileModal } from 'shared/screens/profile/edit-profile-modal/EditProfileModal'

import { MODALS_IDS } from '../index'

import { usePayerStore } from '../../../hooks/use-payer-stores'

// TODO: Move this modal to the payer scope
export const EditProfileModal: React.FC = observer(() => {
  const { modalsManager, payerStore, userStore, userProfilesStore } = usePayerStore()
  const { item: payer } = payerStore

  const isOpen = modalsManager.isOpen(MODALS_IDS.editProfileModal)
  const handleClose = () => modalsManager.close(MODALS_IDS.editProfileModal)

  if (!isOpen) return null

  return (
    <LegacyEditProfileModal
      toggleModal={handleClose}
      userProfileId={payer.userProfileId}
      userStore={userStore}
      userProfilesStore={userProfilesStore}
    />
  )
})
