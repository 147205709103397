import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'

const BASE_URL = '/common/bank_accounts'

export interface BankAccountFile {
  name: string
  contentType: string
  original: string
  size: number
}

export interface BankAccount {
  id: number | string
  bic: string
  iban: string
  file: BankAccountFile
  status: string
  holderAddress: {
    address: string
    city: string
    country: string
    region: string
    zip: string
  }
  holderName: string
}

export const createBankAccountsApi = ({ GET, DELETE, POST, PUT }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<BankAccount[]>>(BASE_URL, data),
  deleteItem: (id: string | number) => DELETE(`${BASE_URL}/${id}`),
  createItem: (data) => POST<Response<BankAccount>>(BASE_URL, data),
  updateItem: (id, data) => PUT<Response<BankAccount>>(`${BASE_URL}/${id}`, data),
})

export type BankAccountsApi = ReturnType<typeof createBankAccountsApi>
