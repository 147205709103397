import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'

import { handleFetchItem, WithFetchItem } from './actions/fetch-item'
import { createCourseThemesApi, CourseThemesApi } from '../api/courseThemes.api'

//TODO: add types
export class CourseThemesStore implements WithFetchItem<any> {
  declare api: CourseThemesApi
  item: any
  constructor() {
    this.api = createCourseThemesApi(apiClient)
    makeAutoObservable(this)
  }

  setItem(item: any) {
    this.item = item
  }

  resetItem() {
    this.item = null
  }

  isLoading = false

  setLoading(value: boolean) {
    this.isLoading = value
  }

  fetchItem = async (id = '', data) => await handleFetchItem<any>(this, id, data)
}
