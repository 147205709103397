import React, { useEffect, useRef } from 'react'
import classNames from 'classnames'

import { TEST_IDS, TooltipProps } from '@elo-ui/types'
import { EloTooltip } from '@elo-ui/components/elo-tooltip'
import {
  EloCheckboxSelectedIcon,
  EloCheckboxUnselectedIcon,
  EloInfoIcon,
  EloCheckboxIndeterminateIcon,
} from '../icons/regular'

import './elo-checkbox.scss'

interface Props {
  disabled?: boolean
  onChange?: React.ChangeEventHandler<HTMLInputElement>
  indeterminate?: boolean
  className?: string
  dataTestId?: string
  ariaLabel?: string
  checked?: boolean
  tooltipOptions?: TooltipProps
  required?: boolean
  size?: 'small' | 'extra-small'
  children?: React.ReactNode
  name?: string
  id?: string
}

export const EloCheckbox: React.FC<Props> = ({
  children,
  disabled = false,
  onChange = () => {},
  indeterminate,
  className,
  ariaLabel = 'Checkbox',
  checked = false,
  dataTestId = TEST_IDS.checkbox,
  tooltipOptions,
  required,
  size = 'small',
  name,
  id,
}) => {
  const checkboxRef = useRef<HTMLInputElement>(null)
  const classes = classNames('elo-checkbox', className)

  useEffect(() => {
    checkboxRef.current.indeterminate = checked ? false : indeterminate
  }, [indeterminate, checked])

  const uncheckedIcon = indeterminate ? (
    <EloCheckboxIndeterminateIcon size={size === 'small' ? 20 : 16} />
  ) : (
    <EloCheckboxUnselectedIcon size={size === 'small' ? 20 : 16} />
  )
  return (
    <label className={classes}>
      <input
        ref={checkboxRef}
        type='checkbox'
        className='elo-checkbox__input'
        disabled={disabled}
        tabIndex={disabled ? -1 : 0}
        aria-disabled={disabled}
        aria-label={ariaLabel}
        checked={checked}
        onChange={onChange}
        data-testid={dataTestId}
        aria-checked={checked}
        name={name}
        {...(id && { id })}
      />
      <span className='elo-checkbox__box'>
        {checked ? <EloCheckboxSelectedIcon size={size === 'small' ? 20 : 16} /> : uncheckedIcon}
      </span>
      {children && <span className='elo-checkbox__label'>{children}</span>}
      {required && <span className='elo-input__label-required'>*</span>}
      {tooltipOptions && (
        <span className='elo-checkbox__label-tooltip'>
          <EloTooltip {...tooltipOptions}>
            {tooltipOptions.children ? tooltipOptions.children : <EloInfoIcon size={16} />}
          </EloTooltip>
        </span>
      )}
    </label>
  )
}
