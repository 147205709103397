import { useEffect } from 'react'
import { observer } from 'mobx-react'

import userflow from 'userflow.js'

import { getElopageConfig } from 'utils/elopageConfig.utils'

import { USERFLOW_INIT_TOKEN, USERFLOW_LOCALE_CODE } from 'constants/general.constants'

import { usePayerStores } from '../../hooks/use-payer-stores'

export const UserFlow = observer(() => {
  const { userStore } = usePayerStores()
  const { item: user } = userStore
  const env = getElopageConfig('env')

  useEffect(() => {
    fetchUserFlowData()
  }, [userStore.item.id])

  const fetchUserFlowData = async () => {
    userflow.init(USERFLOW_INIT_TOKEN[env])

    if (user.id) {
      await userflow.identify(user.id as string, {
        payer_id: user.id,
        name: user.sellerUsername,
        email: user.email,
        locale_code: USERFLOW_LOCALE_CODE[user.locale],
        first_time_signed_in_user: user?.signInCount === 1,
      })
    }
  }

  return null
})
