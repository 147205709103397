import React, { useEffect } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { getCookies } from 'libs/common/cookies'

import { EloButton } from '@elo-ui/components/elo-button'
import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloCloseIcon, EloGearIcon } from '@elo-ui/components/icons/regular'

import { usePayerStore } from '../../../hooks/use-payer-stores'

import { ProfileSwitchingItem } from './ProfileSwitchingItem'

import { MODALS_IDS } from '../index'

import './profile-switching-modal.scss'

export const ProfileSwitchingModal = observer(() => {
  const { userStore, profilesStore, payerAppStore, modalsManager } = usePayerStore()
  const {
    item: { profileTypes },
  } = userStore
  const { isMobile } = payerAppStore
  const { profiles } = profilesStore
  const adminAccessToken = getCookies('admin_access_token') as string
  const showAdminSwitcher = profileTypes.includes('manager') && !adminAccessToken

  const isOpen = modalsManager.isOpen(MODALS_IDS.profileSwitchingModal)

  useEffect(() => {
    profilesStore.getProfiles(profileTypes)
  }, [])

  const handleClose = () => {
    modalsManager.close(MODALS_IDS.profileSwitchingModal)
  }

  const handleSwitchToAdmin = () => {
    modalsManager.close(MODALS_IDS.profileSwitchingModal)
    window.location.href = '/admin'
  }

  const classes = classNames('profile-switching-modal', {
    'is-mobile': isMobile,
    'is-desktop': !isMobile,
  })

  if (isMobile && isOpen) {
    return (
      <div className={classes}>
        <div className='profile-switching-modal__header'>
          <div className='profile-switching-modal__close'>
            <EloButton leftIcon={<EloCloseIcon />} onClick={handleClose} variant='ghost' />
          </div>
        </div>
        <div className='profile-switching-modal__content'>
          {[...profiles.values()].map((props) => (
            <>
              <span className='profile-switching-item__divider'></span>
              <ProfileSwitchingItem key={props.key} isMobile={isMobile} {...props} />
            </>
          ))}
        </div>
      </div>
    )
  }

  return (
    <EloModal className={classes} size='x-large' isOpen={isOpen} onClose={handleClose}>
      <EloModalHeader className='elo-ui-modal__header--center'>{I18n.t('react.shared.accounts')}</EloModalHeader>
      <EloModalBody>
        <div className='profile-switching-modal__content'>
          {[...profiles.values()].map((props) => (
            <ProfileSwitchingItem isMobile={isMobile} key={props.key} {...props} />
          ))}
        </div>
      </EloModalBody>
      <EloModalFooter className='elo-ui-modal__footer--end'>
        {showAdminSwitcher && (
          <EloButton variant='secondary' leftIcon={<EloGearIcon />} onClick={handleSwitchToAdmin}>
            {I18n.t('react.shared.admin')}
          </EloButton>
        )}
      </EloModalFooter>
    </EloModal>
  )
})
