import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'
import { OrdersApi, Order, SingleOrder as Item, createOrdersApi } from '../api/orders.api'
import { handleFetchItem, WithFetchItem } from './actions/fetch-item'
import { handleFetchList, WithFetchList } from './actions/fetch-list'
import { handleApplyFilters, WithFilters } from './actions/filters'
import { handlePaginationChange } from './actions/pagination-change'
import { handleResetStore, WithResetData } from './actions/reset-data'

export class OrdersStore implements WithResetData, WithFetchItem<Item>, WithFetchList<Order>, WithFilters<Order> {
  declare api: OrdersApi
  list: Order[] = []
  item: Item
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }
  expands = []
  scopes = new Map()
  filters = new Map()

  constructor() {
    this.api = createOrdersApi(apiClient)
    makeAutoObservable(this)
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setItem(item: Item) {
    this.item = item
  }

  resetItem() {
    this.item = null
  }

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  resetExpands = () => (this.expands = [])

  setList(list: Order[]) {
    this.list = list
  }

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  setFilter(key, value) {
    this.filters.set(key, value)
  }

  setScope(key, value) {
    this.scopes.set(key, value)
  }

  resetScopes() {
    this.scopes = new Map()
  }

  resetFilters() {
    this.filters = new Map()
  }

  fetchItem = async (id, data = {}) => await handleFetchItem<Item>(this, id, data)

  fetchList = async (data?) => await handleFetchList<Order>(this, data)

  applyFilters = async () => await handleApplyFilters(this)

  applyPagination = async (key, value) => await handlePaginationChange<Order>(this, key, value)

  resetData = () => handleResetStore(this)

  cancelPS = async (orderId) => {
    this.setLoading(true)
    await this.api.cancelOrder(orderId)
    this.setLoading(false)
  }

  continueOrder = async (orderId) => {
    this.setLoading(true)
    await this.api.continueOrder(orderId)
    this.setLoading(false)
  }

  get changedOrders() {
    return this.list.filter((order) => {
      if (!order || !order.appliedVatChange) return false
      if (order.appliedVatChange.payerDecision !== 'pending') return false
      const {
        appliedVatChange: { productsStat: statistic },
      } = order
      return statistic[0].nextToPaySum.after > statistic[0].nextToPaySum.before
    })
  }
}
