import { formatBytes } from 'utils/formatters.utils'

export const FILE_UNITS = {
  bytes: 'B',
  kb: 'KB',
  mb: 'MB',
  gb: 'GB',
  tb: 'TB',
  pb: 'PB',
  eb: 'EB',
  zb: 'ZB',
  yb: 'YB',
}

export const B_BASE = 1024
export const KB_BASE = B_BASE ** 2
export const MB_BASE = B_BASE ** 3
export const GB_BASE = B_BASE ** 4
export const TB_BASE = B_BASE ** 5

export const convertFromBytes = (bytes = 0) => {
  let unit = ''
  let value = bytes

  if (bytes < B_BASE) {
    unit = FILE_UNITS.bytes
  } else if (bytes < KB_BASE) {
    unit = FILE_UNITS.kb
    value /= B_BASE
  } else if (bytes < MB_BASE) {
    unit = FILE_UNITS.mb
    value /= KB_BASE
  } else if (bytes < GB_BASE) {
    unit = FILE_UNITS.gb
    value /= MB_BASE
  } else if (bytes < TB_BASE) {
    unit = FILE_UNITS.tb
    value /= GB_BASE
  } else {
    unit = FILE_UNITS.pb
    value /= TB_BASE
  }

  return `${formatBytes(value)} ${unit}`
}
