import { ApiClient } from 'utils/requests.utils'
import { Nullable } from 'types/helpers'
import { Response } from 'types/responses'

const BASE_URL = '/common/payer'

export interface Payer {
  id: number
  avatar: Nullable<string>
  bankAccountId: Nullable<number>
  firstPurchaseDate: string
  mergeToken: Nullable<string>
  ordersCount: number
  userId: number
  userProfileId: number
  createdAt: string
  updatedAt: string
}

export const createPayerApi = ({ GET, PUT, POST }: ApiClient) => ({
  fetchItem: (id: string | number, data) => GET<Response<Payer>>(`${BASE_URL}/${id}`, data),
  updateItem: (data: Payer) => PUT<Response<Payer>>(BASE_URL, data),
  getCode: () => POST(`${BASE_URL}/get_code`),
  mergeAccounts: (data) => POST(`${BASE_URL}/merge_accounts`, data),
})

export type PayerApi = ReturnType<typeof createPayerApi>
