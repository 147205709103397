import { useContext, useMemo } from 'react'
import { MobXProviderContext } from 'mobx-react'

import { PayerRootStore } from '../stores/payerRoot.store'

export const getRootStores = () => new PayerRootStore()

// Singleton - to avoid recreating payerRootStore on client side on client-side page transitions through Link/router.push
// https://nextjs.org/docs/basic-features/data-fetching/get-server-side-props#when-does-getserversideprops-run
let payerRootStore

function initializeStore(): PayerRootStore {
  const shouldCreateStores = typeof payerRootStore === 'undefined'

  if (shouldCreateStores) {
    payerRootStore = getRootStores()
  }

  return payerRootStore
}

export function usePayerStore() {
  return useMemo(() => initializeStore(), [])
}

export const usePayerStores = () => useContext(MobXProviderContext) as PayerRootStore
