import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'
import { PayerApi, createPayerApi, Payer } from '../api/payer.api'
import { handleFetchItem, WithFetchItem } from './actions/fetch-item'
import { WithLoading } from './actions/toggle-loading'

export class PayerStore implements WithFetchItem<Payer>, WithLoading {
  declare api: PayerApi

  isLoading = true
  item = null

  constructor() {
    this.api = createPayerApi(apiClient)
    makeAutoObservable(this)
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setItem<Payer>(item: Payer) {
    this.item = item
  }

  fetchItem = async (id = '', data) => await handleFetchItem<Payer>(this, id, data)

  updateItem = async (data) => {
    this.setLoading(true)
    try {
      const response = await this.api.updateItem(data)
      this.setItem(response.data)
    } catch (error) {
      console.error(error)
    } finally {
      this.setLoading(false)
    }
  }

  getCode = async () => {
    this.setLoading(true)
    const resp = await this.api.getCode()
    this.setItem(resp)
    this.setLoading(false)
    return resp
  }

  mergeAccounts = (data) => this.api.mergeAccounts(data)
}
