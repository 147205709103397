import SharedStore from 'shared/stores/shared.store'
import { apiClient } from 'utils/requests.utils'
import { createCommentsApi, CommentsApi } from '../api/comments.api'

export class CommentsStore extends SharedStore<any> {
  storeName = 'CommentsStore'
  declare childApi: CommentsApi // hack to make api visible in abstract store
  // CRUD methods inside SharedStore, please check them before overwrite
  // in 99% cases it's enough to use them with promice .then() to do after-actions

  constructor() {
    super()
    this.childApi = createCommentsApi(apiClient)
  }
}
