import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'
import { Pagination } from './pagination-change'

export interface WithResetData {
  pagination?: Pagination
  filters?: Map<string, number> | null | undefined
  scopes?: Map<string, number> | null | undefined
  expands?: any[]
  setPagination?(pagination: Pagination): void
  resetFilters?(): void
  resetScopes?(): void
  resetExpands?(): void
}

export function handleResetStore(store: WithResetData) {
  if (store.pagination) {
    store.setPagination({
      per: DEFAULT_ITEMS_PER_PAGE,
      page: DEFAULT_PAGE,
      total: 0,
    })
  }
  if (store.filters) store.resetFilters()
  if (store.scopes) store.resetScopes()
  if (store.expands) store.resetExpands()
}
