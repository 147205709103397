import React from 'react'
import classNames from 'classnames'

import { EloCardIcon, EloSellerIcon, EloHandshakeIcon, EloUserIcon } from '@elo-ui/components/icons/regular'

import './profile-switching-modal.scss'

const getProfileIcon = (type, iconSize) => {
  switch (type) {
    case 'payer':
      return <EloCardIcon size={iconSize} />
    case 'publisher':
    case 'eloPublisher':
      return <EloHandshakeIcon size={iconSize} />
    case 'seller':
      return <EloSellerIcon size={iconSize} />
    default:
      return <EloUserIcon size={iconSize} />
  }
}

export const ProfileSwitchingItem = (props) => {
  const { type, name, profile, active, disabled, onClick, isMobile } = props
  const iconSize = isMobile ? 16 : 34

  const classes = classNames('profile-switching-item', {
    'is-active': active,
    'is-disabled': disabled,
    'is-mobile': isMobile,
    'is-desktop': !isMobile,
  })

  return (
    <div className={classes} onClick={onClick}>
      <div className='profile-switching-item__icon'>{getProfileIcon(type, iconSize)}</div>
      <div className='profile-switching-item__description'>
        <div className='profile-switching-item__name'>{name}</div>
        <div className='profile-switching-item__profile'>{profile}</div>
      </div>
    </div>
  )
}
