import { ApiClient } from 'utils/requests.utils'
import { Response } from 'types/responses'

const BASE_URL = (sellerId) => `/payer/sellers/${sellerId}/course_themes`

export const createCourseThemesApi = ({ GET }: ApiClient) => ({
  fetchItem: (id: string | number, { sellerId }) => GET<Response<any>>(`${BASE_URL(sellerId)}/${id}`),
})

export type CourseThemesApi = ReturnType<typeof createCourseThemesApi>
