import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'

const BASE_URL = '/payer/course_sessions'

export interface CourseSession {
  full_name: string
  email: string
  product: string
  product_id: string | number
  progress: number
  order_id: string | number
  subscribed_from: string
  subscribed_till: string
  blocked: boolean
  drip_in: boolean
  created_at: string
}

export const createCourseSessionApi = ({ GET, POST }: ApiClient) => ({
  fetchList: (data: Record<string, any>) => GET<PaginatedResponse<CourseSession[]>>(BASE_URL, data),
  fetchItem: (id: string | number, data: Record<string, any>) =>
    GET<Response<CourseSession>>(`${BASE_URL}/${id}`, data),
  cancelItemSubscription: (id: number) => POST(`${BASE_URL}/${id}/cancel_subscription`),
  continueItemSubscription: (id: number) => POST(`${BASE_URL}/${id}/continue_subscription`),
  dripInToggle: (id: number | string) => POST(`${BASE_URL}/${id}/drip_in_toggle`),
  resetCourse: (id: number) => POST(`${BASE_URL}/${id}/reset`),
  resetLesson: (courseSessionId: number, lessonId: number) =>
    POST(`${BASE_URL}/${courseSessionId}/lessons/${lessonId}/reset`),
})

export type CourseSessionsApi = ReturnType<typeof createCourseSessionApi>
