import React, { useRef } from 'react'
import classnames from 'classnames'

import { TEST_IDS } from '@elo-ui/types'
import { EloBellIcon } from '@elo-ui/components/icons/regular'
import { EloAvatar } from '@elo-ui/components/elo-avatar'

import { useClickOutside } from '@elo-ui/hooks/use-click-outside'

import { EloTopBarProfileMenu } from './components/elo-top-bar-profile-menu'
import { EloTopBarInfoMenu } from './components/elo-top-bar-info-menu'

import './elo-top-bar.scss'

interface Notification {}

interface Action {
  label: string
  subLabel?: string
  link: {
    href?: string
    target?: string
    onClick?: () => void
  }
}

interface Language {
  label: string
  value: string
}

interface Props {
  isMobile?: boolean
  user: {
    avatar: string
    name: string
    email: string
  }
  avatar?: string
  currentLanguage: string
  actions: {
    plan?: Action
    profile?: Action
    bankAccounts?: Action
    businessApp?: Action
    helpCenter?: Action
    support?: Action
    whatIsNew?: Action
    signOut?: Action
  }
  customContent?: React.ReactNode
  languages: {
    onChange: (language: string) => void
    options: Language[]
  }
  notifications?: {
    label: string
    options: Notification[]
  }
  dataTestId?: string
}

export const EloTopBar: React.FC<Props> = ({
  isMobile = false,
  notifications = null,
  user,
  actions,
  currentLanguage,
  customContent,
  languages,
  dataTestId = TEST_IDS.topBar,
}) => {
  const menuRef = useRef<HTMLDivElement>(null)
  const [menuIsOpen, setMenuIsOpen] = React.useState(false)
  const infoMenuRef = useRef<HTMLDivElement>(null)
  const [infoMenuIsOpen, setInfoMenuIsOpen] = React.useState(false)

  const classes = classnames('elo-top-bar', {
    'elo-top-bar--mobile': isMobile,
    'elo-top-bar--desktop': !isMobile,
  })
  const hasNotifications = Boolean(notifications?.options?.length)
  const notificationClasses = classnames('elo-top-bar__notification', {
    'elo-top-bar__notification--has-notifications': hasNotifications,
  })

  useClickOutside(menuRef, () => setMenuIsOpen(false))
  useClickOutside(infoMenuRef, () => setInfoMenuIsOpen(false))

  return (
    <div className={classes} data-testid={dataTestId}>
      <div className='elo-top-bar__content'>
        <div className='elo-top-bar__item-custom'>{customContent}</div>
        {notifications && (
          <div className='elo-top-bar__item' ref={infoMenuRef}>
            <div
              onClick={() => setInfoMenuIsOpen(true)}
              className={notificationClasses}
              data-testid={TEST_IDS.topBarNotifications}
            >
              <EloBellIcon />
            </div>
            <EloTopBarInfoMenu
              isMobile={isMobile}
              isOpen={infoMenuIsOpen}
              onClose={() => setInfoMenuIsOpen(false)}
              notifications={notifications}
            />
          </div>
        )}
        <div className='elo-top-bar__item' ref={menuRef}>
          <div onClick={() => setMenuIsOpen(true)} className='elo-top-bar__user' data-testid={TEST_IDS.topBarProfile}>
            <EloAvatar size='large' src={user?.avatar} alt='avatar' />
          </div>
          <EloTopBarProfileMenu
            isMobile={isMobile}
            isOpen={menuIsOpen}
            user={user}
            actions={actions}
            currentLanguage={currentLanguage}
            languages={languages}
            onClose={() => setMenuIsOpen(false)}
          />
        </div>
      </div>
    </div>
  )
}
