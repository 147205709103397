import { makeAutoObservable } from 'mobx'

import { apiClient } from 'utils/requests.utils'
import { DEFAULT_ITEMS_PER_PAGE, DEFAULT_PAGE } from 'constants/pagination.constants'

import { createLessonsApi, LessonsApi, Lesson } from '../api/lessons.api'

import { handleFetchFullList, handleFetchList, WithFetchList } from './actions/fetch-list'
import { handleFetchItem, WithFetchItem } from './actions/fetch-item'

export class LessonsStore implements WithFetchItem<Lesson>, WithFetchList<Lesson> {
  declare api: LessonsApi
  list: Lesson[] = []
  item: Lesson = {
    id: '',
    name: '',
  }
  isLoading = false
  pagination = {
    per: DEFAULT_ITEMS_PER_PAGE,
    page: DEFAULT_PAGE,
    total: 0,
  }

  expands = []
  scopes = new Map()

  constructor() {
    makeAutoObservable(this)
    this.api = createLessonsApi(apiClient)
  }

  setLoading(value) {
    this.isLoading = value
  }

  setItem(item: Lesson) {
    this.item = item
  }

  resetItem() {
    this.item = null
  }

  setExpands = (expand) => {
    this.expands = [...this.expands, ...expand]
  }

  resetExpands = () => (this.expands = [])

  setList(list: Lesson[]) {
    this.list = list
  }

  setPagination(pagination) {
    this.pagination = {
      ...this.pagination,
      ...pagination,
    }
  }

  setScope(key, value) {
    this.scopes.set(key, value)
  }

  fetchItem = async (id, data?) => await handleFetchItem<Lesson>(this, id, data)

  fetchFullList = async (data) => await handleFetchFullList(this, data)

  fetchList = async (data?) => await handleFetchList<Lesson>(this, data)

  finishLesson = async (lessonId, data) => {
    this.setLoading(true)
    const resp = await this.api.finishItem(lessonId, data)
    this.setItem(resp.data)
    this.setLoading(false)
    return resp
  }
}
