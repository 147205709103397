import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'

const BASE_URL = (courseSessionId) => `/payer/course_sessions/${courseSessionId}/lesson_statuses`

export interface LessonStatus {
  id: string | number
  name: string
}

export const createLessonStatusesApi = ({ GET }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<LessonStatus[]>>(BASE_URL(data.courseSessionId), data),
  fetchItem: (id: string | number, data) =>
    GET<Response<LessonStatus>>(`${BASE_URL(data.courseSessionId)}/${id}`, data),
})

export type LessonStatusesApi = ReturnType<typeof createLessonStatusesApi>
