import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse } from 'types/responses'
import { Nullable } from 'types/helpers'

const BASE_URL = '/payer/transfers'

export interface Transfer {
  id: number
  state: string
  amount: string
  authorId: Nullable<number>
  campaignId: Nullable<number>
  canBeRefunded: boolean
  chargebackFee: string
  confirmationToken: Nullable<string>
  confirmationToken2: Nullable<string>
  creditCardId: number
  currencyId: number
  earnings: string
  errorData: any
  extId: string
  fee: string
  feeRates: FeeRates
  form: string
  idForSeller: number
  initReceiverId: Nullable<number>
  initReceiverType: Nullable<string>
  invoice: Invoice
  isCorrection: boolean
  maxToRefund: string
  monthlyInvoiceId: Nullable<number>
  orderToken: string
  p2pVersion: string
  payoutIds: any
  paypalInfo: any
  postProcessed: boolean
  processingFee: string
  processingFeeFormula: string
  provider: string
  providerData: ProviderData
  publisherFee: string
  publisherFeeFormula: string
  receiverId: number
  receiverType: string
  receiverWalletId: number
  refundFee: string
  resellerId: Nullable<number>
  salesTeamMemberFee: string
  salesTeamMemberName: Nullable<string>
  sddMandateId: Nullable<number>
  sellerBuyerData: any
  senderId: number
  senderType: string
  senderWalletId: number
  serviceFee: string
  serviceFeeFormula: string
  successDate: string
  teamMemberFee: string
  teamMemberFeeFormula: string
  total: string
  transferableId: number
  transferableType: string
  vatAmount: string
  vatRate: string
  createdAt: string
  updatedAt: string
  sender: any
  transferable: any
}

interface FeeRates {
  total: number
  planId: number
  serviceStatic: number
  serviceDynamic: number
  processingStatic: number
  processingDynamic: number
}

interface Invoice {
  id: number
  number: string
}

interface ProviderData {
  transfer: string
  ipAddress: Nullable<string>
  skipEmail: Nullable<string>
  browserInfo: Nullable<string>
  virtualBankWire: Nullable<string>
}

export const createTransfersApi = ({ GET }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<Transfer[]>>(BASE_URL, data),
})

export type TransfersAPI = ReturnType<typeof createTransfersApi>
