import { makeAutoObservable } from 'mobx'
import { setCookies } from 'libs/common/cookies'
import { ProfileTypes } from 'types/helpers'

import { getProfileDetails, PROFILE_TYPE_COOKIE_NAME } from 'constants/profile.constants'
import { ELOPAGE_CABINETS_URL, ROOT_URL, STATUSES } from 'constants/general.constants'

import { PayerRootStore } from './payerRoot.store'
type Profiles = ProfileTypes[]

interface ProfileItem {
  key?: string
  id?: number
  username?: string
  item?: any
  teamMemberInvites?: any
  salesTeamMemberInvites?: any
  type?: string
  name?: string
  profile?: string
  active?: boolean
  disabled?: boolean
  onClick?: () => void
}

export class ProfilesStore {
  root: PayerRootStore

  profiles = new Map()

  constructor(rootStore: PayerRootStore) {
    makeAutoObservable(this)
    this.root = rootStore
  }

  getProfileByType = (type: ProfileTypes) => {
    const profile = this.root.userStore.item.profiles.find((item) => item.type === type)

    return {
      ...profile,
      ...profile.attributes,
    }
  }

  addProfile = (key: string, value: ProfileItem) => this.profiles.set(key, value)

  prepareSellerProfile = () => {
    const sellerProfile = this.getProfileByType('seller')
    const { id, username } = sellerProfile
    const profileDetails = getProfileDetails()['seller']
    const profileTypeText = I18n.t('react.shared.profile_type_account', {
      profileType: profileDetails.title,
    })

    const handleClick = () => {
      setCookies(PROFILE_TYPE_COOKIE_NAME, 'cabinet').then(
        () => (window.location.href = `${ROOT_URL}/${ELOPAGE_CABINETS_URL['seller']}`)
      )
    }

    this.addProfile(`seller-${id}`, {
      key: `seller-${id}`,
      type: 'seller',
      name: username || '',
      profile: profileTypeText,
      active: false,
      disabled: false,
      onClick: handleClick,
    })
  }

  preparePayerProfile = () => {
    const payerProfile = this.getProfileByType('payer')
    const { id, username } = payerProfile
    const profileDetails = getProfileDetails()['payer']
    const profileTypeText = I18n.t('react.shared.profile_type_account', {
      profileType: profileDetails.title,
    })

    this.addProfile(`payer-${id}`, {
      key: `payer-${id}`,
      type: 'payer',
      name: username || '',
      profile: profileTypeText,
      active: true,
      disabled: false,
      onClick: () => {},
    })
  }

  preparePublisherProfile = () => {
    const publisherProfile = this.getProfileByType('publisher')
    const { id, username } = publisherProfile
    const profileDetails = getProfileDetails()['publisher']
    const profileTypeText = I18n.t('react.shared.profile_type_account', {
      profileType: profileDetails.title,
    })

    const handleClick = () => {
      setCookies(PROFILE_TYPE_COOKIE_NAME, 'publisher').then(
        () => (window.location.href = `${ROOT_URL}/${ELOPAGE_CABINETS_URL['publisher']}`)
      )
    }

    this.addProfile(`publisher-${id}`, {
      key: `publisher-${id}`,
      type: 'publisher',
      name: username || '',
      profile: profileTypeText,
      active: false,
      disabled: false,
      onClick: handleClick,
    })
  }

  prepareEloPublisherProfile = () => {
    const eloPublisherProfile = this.getProfileByType('elo_publisher')
    const { username, id } = eloPublisherProfile
    const profileDetails = getProfileDetails()['eloPublisher']
    const profileTypeText = I18n.t('react.shared.profile_type_account', {
      profileType: profileDetails.title,
    })

    const handleClick = () => {
      setCookies(PROFILE_TYPE_COOKIE_NAME, 'elo_publisher').then(
        () => (window.location.href = `${ROOT_URL}/${ELOPAGE_CABINETS_URL['elo_publisher']}`)
      )
    }

    this.addProfile(`eloPublisher-${id}`, {
      key: `eloPublisher-${id}`,
      type: 'eloPublisher',
      name: username || '',
      profile: profileTypeText,
      active: false,
      disabled: false,
      onClick: handleClick,
    })
  }

  prepareTeamMemberProfile = () => {
    const teamMemberProfile = this.getProfileByType('team_member')
    const { invites } = teamMemberProfile
    const hasTeamMembers = invites.length > 0

    if (hasTeamMembers) {
      invites.forEach((teamMember) => {
        const { id, username = '', roleName = '' } = teamMember
        const isTeamMemberActive = teamMember.state !== STATUSES.approved
        const isTeamMemberAppActive = teamMember.isAppActive

        const handleClick = async () => {
          await setCookies(PROFILE_TYPE_COOKIE_NAME, 'team_member')
          await setCookies('tm_selected_seller', username)
          window.location.href = `${ROOT_URL}/team_member/${isTeamMemberActive ? 'invites' : ''}`
        }

        this.addProfile(`teamMember-${id}`, {
          key: `teamMember-${id}`,
          type: 'teamMember',
          name: username,
          profile: roleName,
          active: false,
          disabled: !isTeamMemberAppActive,
          onClick: isTeamMemberAppActive && handleClick,
        })
      })
    }
  }

  prepareSalesTeamMemberProfile = () => {
    const salesTeamMemberProfile = this.getProfileByType('sales_team_member')
    const { invites } = salesTeamMemberProfile
    const hasSalesTeamMembers = invites.length > 0

    if (hasSalesTeamMembers) {
      invites.forEach((salesTeamMember) => {
        const { id, username = '', salesTeamName = '', salesTeamId } = salesTeamMember
        const isSalesTeamMemberDisabled = salesTeamMember.state === STATUSES.declined || !salesTeamMember.isAppActive
        const isSalesTeamMemberNotAccepted = salesTeamMember.state !== STATUSES.accepted
        const isSalesTeamMemberRejected = salesTeamMember.state === STATUSES.rejected

        const handleClick = async () => {
          await setCookies(PROFILE_TYPE_COOKIE_NAME, 'sales_team_member')
          await setCookies('tm_sales_team_id', salesTeamId)
          window.location.href = `${ROOT_URL}/sales_team_member/${isSalesTeamMemberNotAccepted ? 'invites' : ''}`
        }

        if (!isSalesTeamMemberRejected) {
          this.addProfile(`salesTeamMember-${id}`, {
            key: `salesTeamMember-${id}`,
            type: 'salesTeamMember',
            name: username,
            profile: salesTeamName,
            active: false,
            disabled: isSalesTeamMemberDisabled,
            onClick: !isSalesTeamMemberDisabled && handleClick,
          })
        }
      })
    }
  }

  getProfiles = (types: Profiles) => {
    if (types.includes('seller')) this.prepareSellerProfile()
    if (types.includes('payer')) this.preparePayerProfile()
    if (types.includes('publisher')) this.preparePublisherProfile()
    if (types.includes('elo_publisher')) this.prepareEloPublisherProfile()
    if (types.includes('team_member')) this.prepareTeamMemberProfile()
    if (types.includes('sales_team_member')) this.prepareSalesTeamMemberProfile()
  }
}
