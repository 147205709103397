import React from 'react'
import classnames from 'classnames'

import { EloCloseIcon, EloBulletsIcon } from '@elo-ui/components/icons/regular'
import { TEST_IDS } from '@elo-ui/types'

import './elo-top-bar-info-menu.scss'

interface Props {
  isMobile?: boolean
  isOpen: boolean
  onClose: () => void
  notifications?: {
    label: string
    options: any[]
  }
}

export const EloTopBarInfoMenu: React.FC<Props> = ({ isMobile = false, isOpen = false, onClose, notifications }) => {
  const infoMenuClasses = classnames('elo-top-bar__info-menu', {
    'elo-top-bar__info-menu--open': isOpen,
    'elo-top-bar__info-menu--mobile': isMobile,
    'elo-top-bar__info-menu--desktop': !isMobile,
  })

  return (
    <div className={infoMenuClasses} data-testid={TEST_IDS.topBarNotificationsMenu}>
      <div className='elo-top-bar__info-menu-header'>
        <button onClick={onClose} className='elo-top-bar__info-menu-close'>
          <EloCloseIcon />
        </button>
      </div>
      <div className='elo-top-bar__info-menu-content'>
        <div className='elo-top-bar__notifications-header'>
          <h5>{notifications.label}</h5>
          <EloBulletsIcon />
        </div>
      </div>
    </div>
  )
}
