import { Response } from 'types/responses'
import { ApiClient, apiClient } from 'utils/requests.utils'
import { ProfileTypes } from 'types/helpers'

import { Profile } from './userProfiles.api'

const BASE_URL = '/common/user'

interface Invite {
  id: number
  username: string
  salesTeamName: string
  salesTeamId: number
  state: string
  isAppActive: boolean
  roleName?: string
}

interface ProfileItem extends Profile {
  username?: string
  fullName?: string
  invites: Invite[]
}

export interface User {
  id: number | string
  profileTypes: ProfileTypes[]
  profiles: {
    id: number | string
    type: ProfileTypes
    attributes: ProfileItem
  }[]
  email: string
  name: string
  timeZoneName: string
  confirmed: boolean
  avatar: {
    s200: string
    original: string
  }
  noPassword: boolean
  sellerUsername: string
  pendingReconfirm: boolean
  unconfirmedEmail: string
  locale: string
  signInCount: number
}

export type UserResp = Response<User>

interface UpdateAvatarBody {
  avatarRemoteUrl: string
  avatarCropX: number
  avatarCropY: number
  avatarCropW: number
  avatarCropH: number
}

interface UpdateTimeZoneBody {
  timeZoneName: string
}

interface UpdateLocaleBody {
  locale: string
}

interface UpdatePasswordBody {
  password: string
  passwordConfirmation: string
  currentPassword?: string
}

export type UpdateItemBody = UpdateAvatarBody | UpdateTimeZoneBody | UpdateLocaleBody | UpdatePasswordBody

interface CreateProfileBody {
  profileType: string
  termsAccepted?: boolean
  username?: string
  epid?: string | boolean
}

interface UpdateEmailBody {
  email: string
  currentPassword: string
  recaptcha: any
}

export const createUserApi = ({ GET, PUT, POST }: ApiClient) => ({
  updateEmail: (body: UpdateEmailBody) => PUT<UserResp>(`${BASE_URL}/update_email`, body),
  createProfile: (body: CreateProfileBody) => POST<UserResp>(`${BASE_URL}/create_profile`, body),
  updateItem: (body: UpdateItemBody) => PUT<UserResp>(BASE_URL, body),
  fetchItem: (data?: { token: string }) => GET<UserResp>(BASE_URL, data),
})

export type UserApi = ReturnType<typeof createUserApi>

const api = createUserApi(apiClient)
export const createProfile = api.createProfile
