import { makeAutoObservable } from 'mobx'
import * as api from 'shared/api/seller.api'
import { PayerRootStore } from './payerRoot.store'

export class SellerStore {
  storeName = 'SellerStore'
  childApi = api
  root: PayerRootStore

  item = {
    protocol: '',
    host: '',
    optionKeys: [],
  }

  constructor(rootStore: PayerRootStore) {
    this.root = rootStore
    makeAutoObservable(this)
  }

  isAppActive = (key) => {
    const { optionKeys } = this.item
    return optionKeys && optionKeys.includes(key)
  }
}
