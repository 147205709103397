import React, { lazy, Suspense, useEffect } from 'react'
import { Switch, Route, useHistory, withRouter } from 'react-router-dom'

import { cancelPendingRequests } from 'utils/request-cancelation.utils'

const ReloadPage = lazy(() => import('shared/components/ReloadPage'))
const SellerResolver = lazy(() => import('./resolvers/SellerResolver'))
const PageNotFound = lazy(() => import('./pages/404'))
const Orders = lazy(() => import('./pages/orders'))
const Order = lazy(() => import('./pages/order'))
const ProfileSettings = lazy(() => import('./pages/profile-settings'))
const BankAccounts = lazy(() => import('./pages/bank-accounts'))

import {
  PAYER_PROFILE_SETTINGS_PATH,
  PAYER_ORDERS_PATH,
  PAYER_OVERVIEW_PATH,
  PAYER_RELOAD_PATH,
  PAYER_NOT_FOUND_PATH,
  PAYER_SELLERS_SHOP_PATH,
  PAYER_ORDER_PATH,
  PAYER_SELLERS_SHOP_THEME_PATH,
  PAYER_SELLERS_SHOP_THEME_PAGE_PATH,
  PAYER_SELLERS_PRODUCT_PATH,
  PAYER_SELLERS_PRODUCTS_PATH,
  PAYER_SELLERS_COURSES_PATH,
  PAYER_BANK_ACCOUNTS_PATH,
} from './constants/routes'

import { LoadingRouterFallback } from './components/common/loading-router-fallback'

const Router = (
  <Suspense fallback={<LoadingRouterFallback type='payer' />}>
    <Switch>
      <Route
        exact
        path={[
          PAYER_OVERVIEW_PATH,
          PAYER_SELLERS_SHOP_PATH,
          PAYER_SELLERS_SHOP_THEME_PATH,
          PAYER_SELLERS_SHOP_THEME_PAGE_PATH,
          PAYER_SELLERS_PRODUCT_PATH,
          PAYER_SELLERS_PRODUCTS_PATH,
          PAYER_SELLERS_COURSES_PATH,
        ]}
        component={SellerResolver}
      />
      <Route exact path={PAYER_ORDER_PATH} component={Order} />
      <Route exact path={PAYER_ORDERS_PATH} component={Orders} />
      <Route exact path={PAYER_PROFILE_SETTINGS_PATH} component={ProfileSettings} />
      <Route exact path={PAYER_BANK_ACCOUNTS_PATH} component={BankAccounts} />
      <Route exact path={PAYER_RELOAD_PATH} component={ReloadPage} />р
      <Route exact path={PAYER_NOT_FOUND_PATH} component={PageNotFound} />
    </Switch>
  </Suspense>
)

export const Routes = withRouter(() => {
  const history = useHistory()

  useEffect(() => {
    history.listen((location) => {
      cancelPendingRequests(location)
    })
  }, [])

  return Router
})
