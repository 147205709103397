import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'

import { copyTextToClipboard } from 'utils/dom.utils'
import { notify } from 'libs/common/notify'

import { EloModal, EloModalHeader, EloModalBody, EloModalFooter } from '@elo-ui/components/elo-modal'
import { EloSelect } from '@elo-ui/components/elo-select'
import { EloButton } from '@elo-ui/components/elo-button'
import { EloInputCopy, EloInput } from '@elo-ui/components/elo-inputs'

import { usePayerStore } from '../../../hooks/use-payer-stores'

import { MODALS_IDS } from '../index'

import './merge-accounts-modal.scss'

const MERGE_FROM_OPTIONS = [
  {
    value: 'mergeCurrent',
    label: I18n.t('react.shared.merging_accounts.form.merge_current_into_another'),
  },
  {
    value: 'mergeAnother',
    label: I18n.t('react.shared.merging_accounts.form.merge_another_into_current'),
  },
]

interface Option {
  value: string
  label: string
}

export const MergeAccountsModal = observer(() => {
  const { payerStore, modalsManager } = usePayerStore()

  const payer = payerStore.item
  const mergeToken = payer?.mergeToken || ''

  const isOpen = modalsManager.isOpen(MODALS_IDS.mergeAccountsModal)

  const [mergeType, setMergeType] = useState<Option>(MERGE_FROM_OPTIONS[0])
  const [newMergeToken, setNewMergeToken] = useState<string>('')

  const [showMergeCodeModal, setShowMergeCodeModal] = useState<boolean>(false)
  const [showMergeInsertCodeModal, setShowMergeInsertCodeModal] = useState<boolean>(false)

  useEffect(() => {
    if (mergeToken === '') {
      payerStore.getCode()
    }
  }, [mergeToken])

  const handleClose = () => {
    modalsManager.close(MODALS_IDS.mergeAccountsModal)
  }

  const handleSubmit = () => {
    if (mergeType.value === 'mergeCurrent') {
      setShowMergeCodeModal(true)
    } else {
      setShowMergeInsertCodeModal(true)
    }

    handleClose()
  }

  const handleMergeAccounts = () => {
    payerStore.mergeAccounts({ code: newMergeToken }).then(({ success }) => {
      if (success) {
        notify('success', I18n.t('react.cabinet.notific.success'))
        setShowMergeInsertCodeModal(false)
      }
    })
  }

  const isMergeCurrent = mergeType.value === 'mergeCurrent'
  const insertMergeTokenDisabled = !newMergeToken

  return (
    <>
      <EloModal className='merge-accounts-modal' isOpen={isOpen} onClose={handleClose} isOverlayFixed>
        <EloModalHeader className='elo-ui-modal__header--center'>
          {I18n.t('react.shared.merging_accounts.merge_payer_modal_title')}
        </EloModalHeader>
        <EloModalBody>
          <p>{I18n.t('react.shared.merging_accounts.merge_payer_modal_body')}</p>
          <EloSelect.Select
            label={I18n.t('react.shared.merging_accounts.merging_options')}
            onChange={(value: Option) => setMergeType(value)}
            options={MERGE_FROM_OPTIONS}
            value={mergeType}
          />
        </EloModalBody>
        <EloModalFooter className='elo-ui-modal__footer--center'>
          <EloButton variant='secondary' onClick={handleClose}>
            {I18n.t('react.shared.close')}
          </EloButton>
          <EloButton type='submit' variant='highlight' onClick={handleSubmit}>
            {isMergeCurrent ? I18n.t('react.common.get_code') : I18n.t('react.common.insert_code')}
          </EloButton>
        </EloModalFooter>
      </EloModal>

      <EloModal
        className='merge-accounts-modal'
        isOpen={showMergeCodeModal}
        onClose={() => setShowMergeCodeModal(false)}
        isOverlayFixed
      >
        <EloModalHeader className='elo-ui-modal__header--center'>
          {I18n.t('react.shared.merging_accounts.get_code_modal_title')}
        </EloModalHeader>
        <EloModalBody>
          <p>{I18n.t('react.shared.merging_accounts.get_code_modal_body')}</p>
          <EloInputCopy value={mergeToken} defaultValue={mergeToken} />
        </EloModalBody>
        <EloModalFooter className='elo-ui-modal__footer--center'>
          <EloButton variant='secondary' onClick={() => setShowMergeCodeModal(false)}>
            {I18n.t('react.shared.close')}
          </EloButton>
          <EloButton
            variant='highlight'
            onClick={() => copyTextToClipboard(mergeToken, I18n.t('react.shared.notific.copied'))}
          >
            {I18n.t('react.shared.copy_code')}
          </EloButton>
        </EloModalFooter>
      </EloModal>
      <EloModal
        className='merge-accounts-modal'
        isOpen={showMergeInsertCodeModal}
        onClose={() => setShowMergeInsertCodeModal(false)}
        isOverlayFixed
      >
        <EloModalHeader className='elo-ui-modal__header--center'>
          {I18n.t('react.shared.merging_accounts.insert_code_modal_title')}
        </EloModalHeader>
        <EloModalBody>
          <p>{I18n.t('react.shared.merging_accounts.insert_code_modal_body')}</p>
          <EloInput
            label={I18n.t('react.shared.merging_accounts.merging_code_label')}
            placeholder={I18n.t('react.shared.merging_accounts.insert_code_placeholder')}
            value={newMergeToken}
            onChange={(e) => setNewMergeToken(e.target.value)}
          />
        </EloModalBody>
        <EloModalFooter className='elo-ui-modal__footer--center'>
          <EloButton variant='secondary' onClick={() => setShowMergeInsertCodeModal(false)}>
            {I18n.t('react.shared.button.cancel')}
          </EloButton>
          <EloButton variant='highlight' onClick={handleMergeAccounts} disabled={insertMergeTokenDisabled}>
            {I18n.t('react.shared.merging_accounts.merge_account_btn')}
          </EloButton>
        </EloModalFooter>
      </EloModal>
    </>
  )
})
