import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'

const BASE_URL = '/payer/covers'

export const createCoversApi = ({ GET, POST, DELETE }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<any[]>>(BASE_URL, data),
  fetchItem: (id: string | number, data) => GET<Response<any>>(`${BASE_URL}/${id}`, data),
  createItem: (data) => POST(BASE_URL, data),
  deleteItem: (id) => DELETE(`${BASE_URL}/${id}`),
})

export type CoversApi = ReturnType<typeof createCoversApi>
