export const PAYER_SELLERS_PRODUCTS_PATH = '/payer/s/:username/products/:sellableId'
export const PAYER_SELLERS_PRODUCT_PATH = '/payer/s/:username/products/:sellableId/:slug'
export const PAYER_SELLERS_COURSES_PATH = '/payer/s/:username/courses/:slug'
export const PAYER_SELLERS_SHOP_PATH = '/payer/s/:username'
export const PAYER_SELLERS_SHOP_THEME_PATH = '/payer/s/:username/'
export const PAYER_SELLERS_SHOP_THEME_PAGE_PATH = '/payer/s/:username/:slug'
export const PAYER_PRODUCTS_PATH = '/payer/products'
export const PAYER_ORDER_PATH = '/payer/orders/:id/'
export const PAYER_ORDERS_PATH = '/payer/orders'
export const PAYER_PROFILE_SETTINGS_PATH = '/payer/profile_settings'
export const PAYER_BANK_ACCOUNTS_PATH = '/payer/bank_accounts'
export const PAYER_OVERVIEW_PATH = '/payer'
export const PAYER_RELOAD_PATH = '/s*'
export const PAYER_NOT_FOUND_PATH = '*'
