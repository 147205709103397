import React from 'react'

import { StatsigProvider, useClientAsyncInit } from '@statsig/react-bindings'

import { PAYER_STATSIG_CLIENT_KEYS } from 'libs/configs'

import { createFingerPrint } from 'utils/fingerprint.utils'
import { setCookies, getCookies } from 'libs/common/cookies'

const getUserSessionId = () => {
  const maxAge = 60 * 60 * 24 * 365 // 365 days
  let userSessionID = getCookies('payer_session_id')

  if (!userSessionID) {
    userSessionID = createFingerPrint()
    setCookies('payer_session_id', userSessionID, { maxAge })
  }

  return userSessionID
}

// it is temporary solution only for new payer experience release
// will be reworked after full migration to new cabinet
export const StatsigContainer = ({ children }) => {
  const environment = { tier: process.env.ENV }
  const CLIENT_KEY = PAYER_STATSIG_CLIENT_KEYS[process.env.ENV]

  const user = {
    userID: getUserSessionId(),
    statsigEnvironment: environment,
  }

  const { client } = useClientAsyncInit(CLIENT_KEY, user)

  return (
    <StatsigProvider sdkKey={CLIENT_KEY} client={client} user={user}>
      {children}
    </StatsigProvider>
  )
}
