import { makeAutoObservable } from 'mobx'
import { apiClient } from 'utils/requests.utils'
import { QuizzesApi, createQuizzesApi } from '../api/quizzes.api'
import { WithFetchItem } from './actions/fetch-item'

export class QuizzesStore implements WithFetchItem<any> {
  declare api: QuizzesApi
  item: any = {}
  isLoading = false

  constructor() {
    this.api = createQuizzesApi(apiClient)
    makeAutoObservable(this)
  }

  setLoading(value: boolean) {
    this.isLoading = value
  }

  setItem(item: any) {
    this.item = item
  }

  fetchItem = async (courseSessionId: string | number, lessonId: string | number) => {
    this.setLoading(true)
    const resp = await this.api.fetchQuiz(courseSessionId, lessonId)
    this.setItem(resp.data || {})
    this.setLoading(false)
    return resp
  }

  submitQuiz = async (courseSessionId: string | number, lessonId: string | number, data: Record<string, any>) => {
    this.setLoading(true)
    const resp = await this.api.submitQuiz(courseSessionId, lessonId, data)
    this.setItem(resp.data || {})
    this.setLoading(false)
    return resp
  }
}
