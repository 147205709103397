import { SUPPORT_LINKS } from 'constants/helpCenter.constants'
import { PAYER_BANK_ACCOUNTS_PATH, PAYER_PROFILE_SETTINGS_PATH } from './routes'

export const TOPBAR_ACTIONS = {
  profile: {
    label: I18n.t('react.payer.common.profile'),
    link: {
      href: PAYER_PROFILE_SETTINGS_PATH,
    },
  },
  bankAccounts: {
    label: I18n.t('react.payer.common.bank_accounts'),
    link: {
      href: PAYER_BANK_ACCOUNTS_PATH,
    },
  },
  helpCenter: {
    label: SUPPORT_LINKS[0].label,
    link: {
      href: SUPPORT_LINKS[0].link,
    },
  },
  support: {
    label: SUPPORT_LINKS[1].label,
    link: {
      href: SUPPORT_LINKS[0].link,
    },
  },
}

export const TOPBAR_NOTIFICATIONS = {
  label: 'Notifications',
  options: [],
}
