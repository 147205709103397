import { ApiClient } from 'utils/requests.utils'
import { Response } from 'types/responses'

const BASE_URL = '/payer/sellers'

export interface SellerFonts {
  list?: []
}

export const createSellerFontsApi = ({ GET }: ApiClient) => ({
  fetchList: (id) => GET<Response<SellerFonts>>(`${BASE_URL}/${id}/seller_fonts`),
})

export type SellerFontsApi = ReturnType<typeof createSellerFontsApi>
