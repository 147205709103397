import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'

const BASE_URL = (sellerId) => `/payer/sellers/${sellerId}/products`

export interface Product {
  id: string | number
  name: string
}

export const createProductsApi = ({ GET }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<Product[]>>(BASE_URL(data.sellerId), data),
  fetchItem: (id: string | number, data) => GET<Response<Product>>(`${BASE_URL(data.sellerId)}/${id}`, data),
})

export type ProductsApi = ReturnType<typeof createProductsApi>
