import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse } from 'types/responses'
import { SellableItem } from './sellables.api'

const BASE_URL = '/payer/sellable_items'

export const createSellableItemsApi = ({ GET }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<SellableItem[]>>(BASE_URL, data),
})

export type SellableItemsAPI = ReturnType<typeof createSellableItemsApi>
