import { ApiClient } from 'utils/requests.utils'
import { PaginatedResponse, Response } from 'types/responses'

const BASE_URL = '/payer/membership_sessions'

export interface MembershipSession {
  fullName: string
  email: string
  product: string
  productId: string | number
  progress: number
  orderId: string | number
  subscribedFrom: string
  subscribedTill: string
  blocked: boolean
  dripIn: boolean
  createdAt: string
}

export const createMembershipSessionApi = ({ GET, POST }: ApiClient) => ({
  fetchList: (data: Record<string, any>) => GET<PaginatedResponse<MembershipSession[]>>(BASE_URL, data),
  fetchItem: (id: string | number, data: Record<string, any>) =>
    GET<Response<MembershipSession>>(`${BASE_URL}/${id}`, data),
  dripInToggle: (id: number | string) => POST(`${BASE_URL}/${id}/drip_in_toggle`),
})

export type MembershipSessionsApi = ReturnType<typeof createMembershipSessionApi>
