import { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import { SidebarItemProps } from '@elo-ui/types'

export const usePayerNavigation = (items: SidebarItemProps[], dependencies: any = null) => {
  const [navigationItems, setSidebarItems] = useState<SidebarItemProps[]>(null)
  const [activeNavigationItem, setActiveNavigationItem] = useState<SidebarItemProps>(null)
  const location = useLocation()

  const getItem = (item: SidebarItemProps, currentPath: string) => {
    const isHidden = typeof item?.isHidden === 'function' ? item.isHidden(dependencies) : false
    const isActive = typeof item?.isActive === 'function' ? item.isActive(dependencies) : false

    if (item.link === currentPath) {
      setActiveNavigationItem(item)
    }

    if (isActive) {
      setActiveNavigationItem(item)
    }

    return {
      ...item,
      isHidden: isHidden,
    }
  }

  useEffect(() => {
    const currentPath = location.pathname

    items?.map((item) => {
      if (Boolean(item?.submenu?.length)) {
        item.submenu?.map((subItem) => getItem(subItem, currentPath))
      }

      return getItem(item, currentPath)
    })

    setSidebarItems(items)
  }, [location.pathname, dependencies, items])

  return { navigationItems, activeNavigationItem }
}
