import { ApiClient } from 'utils/requests.utils'
import { Nullable, ProductType } from 'types/helpers'
import { PaginatedResponse, Response } from 'types/responses'

const BASE_URL = '/payer/sellables'

export interface SellableItem {
  id: number
  expiresAt: Nullable<string>
  file: SellableItemFile
  fileContentType: string
  fileFileName: string
  fileFileSize: number
  fileUpdatedAt: string
  productId: number
  sellableId: number
  ticketDateId: number
  ticketId: number
  ticketsCount: number
  createdAt: string
  updatedA: string
  membershipThemeId: number
  name: string
  description: string
  sellerUsername: string
  form: ProductType
  covers: any[]
  soldDate: string
  progress: number
  location: string
  itemsCount: number
  sellerId: string
  supportFirstName: string
  supportLastName: string
  supportEmail: string
  supportPhone: string
  order: {
    id: string
  }
  accessSessionLimit?: any
  courseSessionState?: string
  membershipSessionState?: string
  courseSessionId?: number
  unsubscribed?: boolean
  notifyAboutDripIn?: boolean
}

interface SellableItemFile {
  original: string
  name: string
}

export const createSellablesApi = ({ GET }: ApiClient) => ({
  fetchList: (data) => GET<PaginatedResponse<SellableItem[]>>(BASE_URL, data),
  fetchItem: (id: string | number, data) => GET<Response<SellableItem>>(`${BASE_URL}/${id}`, data),
  fetchMembershipSellable: (id: string | number) => GET<Response<SellableItem>>(BASE_URL, { id }),
  checkAccess: (id: string | number) => GET<Response<SellableItem>>(`${BASE_URL}/${id}/check_access`),
})

export type SellablesAPI = ReturnType<typeof createSellablesApi>

export const defaultSellableItem = {
  id: null,
  expiresAt: '',
  file: {
    original: '',
    name: '',
  },
  fileContentType: '',
  fileFileName: '',
  fileFileSize: null,
  fileUpdatedAt: '',
  productId: null,
  sellableId: null,
  ticketDateId: null,
  ticketId: null,
  ticketsCount: null,
  createdAt: '',
  updatedA: '',
  membershipThemeId: null,
  name: '',
  description: '',
  sellerUsername: null,
  form: null,
  covers: [],
  soldDate: '',
  progress: null,
  location: '',
  itemsCount: null,
  sellerId: '',
  supportFirstName: '',
  supportLastName: '',
  supportEmail: '',
  supportPhone: '',
  order: {
    id: '',
  },
}
