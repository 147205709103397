import React from 'react'
import { Helmet } from 'react-helmet'
import { useHistory } from 'react-router-dom'

import { getDefaultMetaTagsConfig, getMetaTags } from 'utils/metaTags.utils'
import { usePayerStores } from '../../hooks/use-payer-stores'

export const MetaTags = () => {
  const { sellerStore } = usePayerStores()
  const { item } = sellerStore

  const history = useHistory()
  const pathname = history.location.pathname
  const metaData = {
    url: `${item.protocol}://${item.host}${pathname}`,
    domain: `${item.protocol}://${item.host}`,
  }

  const metaTags = getMetaTags(getDefaultMetaTagsConfig(metaData))

  return <Helmet htmlAttributes={{ lang: I18n.locale }}>{metaTags}</Helmet>
}
