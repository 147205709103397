import React from 'react'
import classNames from 'classnames'

import { Link } from 'react-router-dom'

import { EloCaretUpIcon, EloCaretDownIcon } from '@elo-ui/components/icons/regular'
import { SidebarItemProps } from '@elo-ui/types'

export const EloSidebarItem: React.FC<SidebarItemProps> = (item) => {
  const {
    id,
    link,
    href,
    target = '_self',
    icon,
    label,
    badge,
    submenu,
    isHidden,
    activeItem,
    openedItem,
    onItemCLick,
    onOpenCLick,
  } = item

  const isOpen = openedItem && openedItem?.id === item?.id
  const isActive = activeItem && activeItem?.id === item?.id
  const itemClass = classNames('elo-sidebar__item', {
    'elo-sidebar__item--open': isOpen,
    'elo-sidebar__item--active': isActive,
    'elo-sidebar__item--has-submenu': submenu,
  })
  const submenuClass = classNames('elo-sidebar__submenu', {
    'elo-sidebar__submenu--open': isOpen,
  })

  const handleOnClick = (e, item) => {
    if (submenu) {
      e.preventDefault()

      if (isOpen) {
        onOpenCLick(null)
      } else {
        onOpenCLick(item)
      }
    } else {
      onItemCLick(item)
    }
  }

  const SubmenuIcon = () => {
    if (submenu) {
      return (
        <button className='elo-sidebar__submenu-button' onClick={(e) => handleOnClick(e, item)}>
          {isOpen ? <EloCaretUpIcon /> : <EloCaretDownIcon />}
        </button>
      )
    }

    return null
  }

  if (isHidden) {
    return null
  }

  const linkTo = href ? { pathname: href } : link

  return (
    <li key={id}>
      <div className={itemClass} onClick={(e) => handleOnClick(e, item)}>
        <Link to={linkTo} target={target}>
          {icon && <div className='elo-sidebar__item-icon'>{icon}</div>}
          <div className='elo-sidebar__item-label'>{label}</div>
          {badge && (
            <div className='elo-sidebar__item-badge'>
              <span>{badge?.label}</span>
              <span>{badge?.icon}</span>
            </div>
          )}
        </Link>
        {submenu && <SubmenuIcon />}
      </div>
      {submenu && isOpen && (
        <ul className={submenuClass}>
          {submenu.map((item) => (
            <EloSidebarItem key={item?.id} {...item} activeItem={activeItem} onItemCLick={onItemCLick} />
          ))}
        </ul>
      )}
    </li>
  )
}
