import { getCookies, setCookies } from 'libs/common/cookies'
import { apiClient } from 'utils/requests.utils'

import { createViewLogsApi, ViewLogsApi } from '../api/viewLogs.api'

export class ViewLogsStore {
  declare api: ViewLogsApi
  constructor() {
    this.api = createViewLogsApi(apiClient)
  }

  trackViewLog = async (pms) => {
    await this.api.createItem({
      ...pms,
      clickerToken: this.getClickerToken(),
    })
  }

  getClickerToken() {
    return (
      getCookies('clicker_token') ||
      (setCookies('clicker_token', this.generateClickerToken()) && getCookies('clicker_token'))
    )
  }

  generateClickerToken() {
    return Math.random().toString(36).substr(2)
  }
}
