import { WithLoading } from './toggle-loading'

export interface WithUpdateItem<I> extends WithLoading {
  item: I
  setItem(item: I): void
  api: any
}

export async function handleUpdateItem<I>(store: WithUpdateItem<I>, id: string | number, params) {
  store.setLoading(true)
  const resp = await store.api.updateItem(id, params)
  const { data, success } = resp || {}
  if (data && success) store.setItem(data)
  store.setItem(resp)
  store.setLoading(false)
  return resp
}
