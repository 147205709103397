import { ABLEFY_PREFIX, TTL_OPTIONS } from 'constants/domains.constants'
import { DEFAULT_CURRENCY } from '../constants/currencies.constants'
import { LANGUAGES } from '../constants/general.constants'

export const formatOptions = [
  {
    locale: LANGUAGES.de,
    maximumFractionDigits: 2,
  },
  {
    maximumFractionDigits: 2,
  },
  {
    maximumFractionDigits: 1,
  },
  {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  },
  {
    currency: DEFAULT_CURRENCY,
    style: 'currency',
  },
]

interface LocaleData {
  locale: string
  options?: object
}

export const [
  formatGermanNumber /** formatNumber(5000) -> "5.000" */,
  formatNumber /** formatNumber(5000.64, { locale: 'en' }) -> "5,000.64" */,
  formatBytes /** formatBytes(5000.64, { locale: 'en' }) -> "5,000.6" */,
  formatPrice /** formatPrice(5000.64, { locale: 'en' }) -> "5,000.64" */,
  formatToMoney /** formatToMoney(5000.64, { locale: 'de', currency: 'eur' }) -> "5.000,64 €" */,
] = formatOptions.map(
  (item) =>
    (value: number, localeData?: LocaleData): string =>
      new Intl.NumberFormat(localeData?.locale, {
        ...item,
        ...localeData?.options,
      }).format(value || 0)
)

export const formatTtlValue = (value) => {
  const selectedOption = TTL_OPTIONS.find((item) => Number(item.value) === Number(value))

  return (selectedOption && selectedOption.label) || ''
}

export const formatDnsHostName = (value) => `${value}${ABLEFY_PREFIX}`
