import { matchPath } from 'react-router-dom'
import { EloPackageIcon, EloQuestionCircleIcon, EloReceiptIcon, EloGearIcon } from '@elo-ui/components/icons/regular'

import React from 'react'
import {
  PAYER_PRODUCTS_PATH,
  PAYER_SELLERS_SHOP_PATH,
  PAYER_SELLERS_SHOP_THEME_PAGE_PATH,
  PAYER_SELLERS_SHOP_THEME_PATH,
  PAYER_BANK_ACCOUNTS_PATH,
  PAYER_PROFILE_SETTINGS_PATH,
  PAYER_ORDERS_PATH,
  PAYER_ORDER_PATH,
} from './routes'

export const SIDEBAR_ITEMS = [
  {
    id: 'products',
    link: '/payer',
    label: I18n.t('react.shared.nav.products'),
    icon: <EloPackageIcon />,
    isActive: (dependencies: { location: any }) => {
      const { location } = dependencies
      const PRODUCTS_ROUTS = [
        PAYER_PRODUCTS_PATH,
        PAYER_SELLERS_SHOP_THEME_PAGE_PATH,
        PAYER_SELLERS_SHOP_THEME_PATH,
        PAYER_SELLERS_SHOP_PATH,
      ]

      return PRODUCTS_ROUTS.some((route) => matchPath(location.pathname, route))
    },
  },
  {
    id: 'payments',
    link: PAYER_ORDERS_PATH,
    label: I18n.t('react.payer.common.billing_and_orders'),
    icon: <EloReceiptIcon />,
    isActive: (dependencies: { location: any }) => {
      const { location } = dependencies
      const ORDERS_ROUTS = [PAYER_ORDERS_PATH, PAYER_ORDER_PATH]

      return ORDERS_ROUTS.some((route) => matchPath(location.pathname, route))
    },
  },
  {
    id: 'settings',
    label: I18n.t('react.shared.settings'),
    link: PAYER_PROFILE_SETTINGS_PATH,
    icon: <EloGearIcon />,
    submenu: [
      {
        id: 'profile',
        link: PAYER_PROFILE_SETTINGS_PATH,
        label: I18n.t('react.shared.profile'),
        isActive: (dependencies: { location: any }) => {
          return Boolean(matchPath(dependencies?.location.pathname, PAYER_PROFILE_SETTINGS_PATH))
        },
      },
      {
        id: 'bank-accounts',
        link: PAYER_BANK_ACCOUNTS_PATH,
        label: I18n.t('react.shared.bank_accounts'),
        isActive: (dependencies: { location: any }) => {
          return Boolean(matchPath(dependencies?.location.pathname, PAYER_BANK_ACCOUNTS_PATH))
        },
      },
    ],
    isActive: (dependencies: { location: any }) => {
      const { location } = dependencies
      const SETTINGS_ROUTES = [PAYER_PROFILE_SETTINGS_PATH, PAYER_BANK_ACCOUNTS_PATH]

      return SETTINGS_ROUTES.some((route) => matchPath(location.pathname, route))
    },
  },
  {
    id: 'help-center',
    href: `https://support.ablefy.io/payer`,
    target: '_blank',
    label: I18n.t('react.shared.nav.help'),
    icon: <EloQuestionCircleIcon />,
  },
]
export const FOOTER_LOGO = (
  <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M20.7949 20.7949C20.05 21.5397 19.0206 22.0007 17.8835 22C17.2083 22 16.5716 21.8375 16.009 21.5492C15.2393 21.1548 14.61 20.5256 14.2157 19.756C13.9289 19.1932 13.7664 18.5574 13.7664 17.8829V17.7753C13.776 17.3875 13.8396 17.0129 13.9494 16.6602C13.9494 16.6587 13.9501 16.6573 13.9494 16.6566C14.147 16.0163 14.253 15.3359 14.2537 14.6306C14.2537 13.7306 14.0803 12.8708 13.7657 12.0821C13.4166 11.2056 12.8921 10.4182 12.2365 9.76196C11.5809 9.10567 10.7929 8.58111 9.91637 8.23276C9.12763 7.91814 8.26791 7.74556 7.36795 7.74472C6.66262 7.74472 5.98217 7.85164 5.34195 8.04917C5.34195 8.04917 5.33975 8.04917 5.33829 8.04917C4.98562 8.15888 4.61174 8.22258 4.22323 8.23206H4.11567C3.44179 8.23206 2.80524 8.07036 2.24257 7.78278C1.47286 7.38842 0.843615 6.75919 0.449244 5.98948C0.162431 5.42901 0 4.79245 0 4.11639C0 2.97937 0.460229 1.9499 1.20506 1.20507C1.94991 0.460223 2.9801 0 4.11639 0H21.8536C21.9342 0 21.9999 0.0658517 21.9999 0.146326V17.8836C21.9999 19.0206 21.5398 20.0501 20.7942 20.7956L20.7949 20.7949Z'
      fill='#2BFF99'
    />
    <path
      d='M7.53437 18.1615C9.58075 18.1615 11.2397 16.5027 11.2397 14.4563C11.2397 12.41 9.58075 10.751 7.53437 10.751C5.48801 10.751 3.8291 12.41 3.8291 14.4563C3.8291 16.5027 5.48801 18.1615 7.53437 18.1615Z'
      fill='#2BFF99'
    />
  </svg>
)

export const LOGO = (
  <svg width='170' height='32' viewBox='0 0 170 32' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M26.6175 34.8035C25.6641 35.7569 24.3464 36.347 22.891 36.346C22.0266 36.346 21.2118 36.1381 20.4915 35.7691C19.5063 35.2642 18.7008 34.4588 18.1961 33.4736C17.829 32.7534 17.621 31.9396 17.621 31.0761V30.9384C17.6333 30.442 17.7147 29.9625 17.8552 29.5111C17.8552 29.5092 17.8561 29.5074 17.8552 29.5064C18.1081 28.6869 18.2438 27.8159 18.2448 26.9132C18.2448 25.7612 18.0228 24.6607 17.6201 23.6512C17.1734 22.5292 16.5019 21.5214 15.6627 20.6814C14.8236 19.8413 13.815 19.1698 12.6929 18.724C11.6833 18.3213 10.5829 18.1003 9.43098 18.0993C8.52816 18.0993 7.65718 18.2361 6.8377 18.489H6.83302C6.38161 18.6295 5.90303 18.7109 5.40572 18.7231H5.26805C4.4055 18.7231 3.5907 18.5161 2.8705 18.148C1.88526 17.6432 1.07983 16.8378 0.575037 15.8525C0.207912 15.1352 0 14.3204 0 13.455C0 11.9996 0.589085 10.6819 1.54249 9.72852C2.49589 8.77512 3.81454 8.18604 5.26899 8.18604H27.9727C28.0757 8.18604 28.16 8.27032 28.16 8.37334V31.077C28.16 32.5324 27.5709 33.8501 26.6165 34.8044L26.6175 34.8035Z'
      fill='#2BFF99'
    />
    <path
      d='M9.61833 31.904C12.5118 31.904 14.8574 29.5585 14.8574 26.665C14.8574 23.7715 12.5118 21.4259 9.61833 21.4259C6.7249 21.4259 4.37926 23.7716 4.37926 26.665C4.37926 29.5583 6.72487 31.904 9.61833 31.904Z'
      fill='#2BFF99'
    />
    <g clip-path='url(#clip0_745_4890)'>
      <path
        d='M64.4287 20.4602C64.4287 15.899 61.0403 12.4673 56.5226 12.4673C51.9614 12.4673 48.573 15.899 48.573 20.4602C48.573 25.0213 51.9614 28.4532 56.5226 28.4532C61.0403 28.4532 64.4287 25.0214 64.4287 20.4602ZM44.7503 20.4602C44.7503 13.8573 49.4853 8.86169 55.8276 8.86169C59.52 8.86169 62.5607 10.5993 64.4287 13.3795L65.0836 9.38302H68.1645V31.5374H65.0836L64.4287 27.5409C62.5607 30.321 59.52 32.0587 55.8276 32.0587C49.4853 32.0587 44.7503 27.063 44.7503 20.4602Z'
        fill='white'
      />
      <path
        d='M92.6125 20.4602C92.6125 15.899 89.2243 12.4673 84.663 12.4673C80.1452 12.4673 76.757 15.899 76.757 20.4602C76.757 25.0214 80.1452 28.4532 84.663 28.4532C89.2243 28.4532 92.6125 25.0215 92.6125 20.4602ZM73.0212 0.26062H76.757V13.3795C78.6249 10.5993 81.6657 8.86172 85.3581 8.86172C91.7003 8.86172 96.4787 13.8573 96.4787 20.4602C96.4787 27.0631 91.7003 32.0587 85.3581 32.0587C81.6657 32.0587 78.6249 30.3211 76.757 27.5409L76.1021 31.5375H73.0212V0.26062Z'
        fill='white'
      />
      <path d='M103.807 0.26062H100.071V31.5375H103.807V0.26062Z' fill='white' />
      <path
        d='M130.14 25.6115C128.239 29.4928 124.124 32.0587 119.193 32.0587C112.373 32.0587 107.29 27.063 107.29 20.4602C107.29 13.8573 112.373 8.86169 119.063 8.86169C125.796 8.86169 130.835 13.8573 130.835 20.4602C130.835 20.9815 130.791 21.5462 130.748 22.0241H111.287C111.938 25.9336 115.066 28.6269 119.236 28.6269C122.222 28.6269 124.841 27.2429 126.149 25.1447L130.14 25.6115ZM111.33 18.6792H126.882C126.23 14.9867 123.103 12.2934 119.063 12.2934C115.11 12.2934 112.069 14.8999 111.33 18.6792Z'
        fill='white'
      />
      <path
        d='M135.544 12.7714H131.504V9.38303H135.544V6.86348C135.544 2.51944 138.193 0 142.928 0H145.578V3.43174H143.276C140.8 3.43174 139.279 4.90874 139.279 7.29794V9.38303H145.231V12.7714H139.279V31.5375H135.544V12.7714Z'
        fill='white'
      />
      <path
        d='M156.273 31.4506L146.629 9.38306H150.886L158.358 27.0631L165.83 9.38306H170L156.664 39.7911H152.624L156.273 31.4506Z'
        fill='white'
      />
    </g>
    <defs>
      <clipPath id='clip0_745_4890'>
        <rect width='125.25' height='39.7911' fill='white' transform='translate(44.7503)' />
      </clipPath>
    </defs>
  </svg>
)
